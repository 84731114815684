import React from 'react';
import { Link } from 'react-router-dom';
import './Project.css';

export default function Project(props) {

  return (
    <div className="card-container">
        <div className="card">
            <div className="card-img"> 
                {
                    (props.project_fields.images && props.project_fields.images.length > 0) ? 
                    <img src={props.project_fields.images[0].publicUrl} alt={props.project_fields.images[0].fileAlt} aria-hidden="true"/> :
                    <div className="placeholder image"></div>
                }
                {
                    (props.project_fields.volunteerFields) &&
                    <h3 className="badge">  
                        {(Array.isArray(props.project_fields.volunteerFields) && props.project_fields.volunteerFields.length > 0) && props.project_fields.volunteerFields[0]?.title} 
                    </h3>
                }
                
            </div>
            <div className="card-title-wrapper">
                <h4>{props.project_fields.title}</h4>
                <div className="title-separator"></div>
            </div>
            <div className="card-description-wrapper">
                <p className="card-description">{props.project_fields.parentOrganizationTitle}</p>
            </div>
            <div className="card-info-item card-location"> 
                <svg className="location-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="#298CCA">
                    <path d="M7.997 10.481c-1.773 0-3.215-1.415-3.215-3.153 0-1.74 1.442-3.154 3.215-3.154 1.772 0 3.214 1.415 3.214 3.154 0 1.738-1.442 3.153-3.214 3.153m0-10.46C3.587.02 0 3.54 0 7.866c0 2.695 3.337 8.617 6.136 13.11A2.176 2.176 0 007.996 22c.766 0 1.462-.383 1.861-1.024 2.8-4.493 6.136-10.415 6.136-13.11 0-4.326-3.587-7.845-7.996-7.845" fillRule="evenodd"></path>
                </svg>
                <p data-default-text="מיקום">
                   {
                    props.project_fields?.addresses?.map((item, index) => {
                        return  index < 10 && <span key={index}>{(index > 0 && index !== props.project_fields.addresses.length) ? ", " : ""}{item.cityTitle}</span>
                    })
                   }
                </p>
            </div>
            <div className="card-info-item working-mode"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                    <g fill="#298CCA" fillRule="nonzero">
                        <path d="M.527 13h13.795a.51.51 0 0 0 .339-.13C14.793 12.752 17.816 9.98 18 4H3.184C3 9.429.217 11.98.188 12.006a.588.588 0 0 0-.156.624.53.53 0 0 0 .495.37zM17.466 1h-2.673V.5c0-.28-.235-.5-.534-.5-.3 0-.535.22-.535.5V1h-2.707V.5c0-.28-.236-.5-.535-.5-.3 0-.534.22-.534.5V1H7.276V.5c0-.28-.236-.5-.535-.5-.3 0-.534.22-.534.5V1H3.534C3.235 1 3 1.22 3 1.5V3h15V1.5c0-.28-.235-.5-.534-.5z" />
                        <path d="M15.288 13.691c-.29.222-.655.343-1.03.343H3v1.475c0 .271.239.491.534.491h13.932c.295 0 .534-.22.534-.491V10c-1.03 2.324-2.468 3.503-2.712 3.691z" />
                    </g>
                </svg>
                <p>{props.project_fields.dateProjectText}</p>
            </div>
            <div className="card-info-item vacancies"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                    <g fill="#298CCA" fillRule="nonzero">
                        <circle cx="9" cy="3" r="3" />
                        <circle cx="16" cy="4" r="2" />
                        <circle cx="3" cy="4" r="2" />
                        <path d="M4 8.526c-.645-.602-1.23-.523-1.976-.523C.908 8.003 0 9.031 0 10.293V14c0 .548.393.994.877.994 2.087 0 1.836.043 1.836-.102 0-2.626-.274-4.552 1.287-6.366zM9.891 7.044c-1.614-.137-3.016.002-4.226 1.019-2.025 1.65-1.635 3.874-1.635 6.593 0 .72.575 1.316 1.293 1.316 7.79 0 8.1.256 8.562-.785.151-.353.11-.24.11-3.61 0-2.677-2.276-4.533-4.104-4.533zM15.976 8.003c-.75 0-1.332-.078-1.976.523 1.549 1.8 1.287 3.594 1.287 6.365 0 .147-.208.103 1.805.103.5 0 .908-.462.908-1.03v-3.67c0-1.263-.908-2.29-2.024-2.29z" />
                    </g>
                </svg>
                <p>{props.project_fields.requiredVolunteerQuota} מקומות נותרו</p>
            </div>
            <div className="card-footer"> 
                <Link to={`/${props.partnerName}/project/${props.project_fields.projectID}`} className="btn btn-blue" title="לפרטים והרשמה לפרויקט name">לפרטים ולהתנדבות</Link> 
            </div>
        </div>
    </div>
  )
}
