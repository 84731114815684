import React from 'react';

import AccountVolunteeringTableEmpty from '../account-volunteering-table-empty/AccountVolunteeringTableEmpty';
import AccountVolunteeringTable from '../account-volunteering-table/AccountVolunteeringTable';
import './AccountVolunteering.css';

export default function AccountVolunteering(props) {

  const leadingColor = props.partner.partnerConfig.leadingColor;
  const isContent = true;  
  const displayContent = isContent ? <AccountVolunteeringTable partner={props.partner} leading_color={leadingColor}/> : <AccountVolunteeringTableEmpty/>;
  
  return (    
    <>
        {displayContent}
    </>
  )
}
