import React, { useState } from 'react'
import H1Title from '../../components/h1-title/H1Title'
import './Contactus.css'
import Input from '../../components/input/Input'
import { TextareaAutosize } from '@mui/material';
import CrmUtils from '../../services/crm-utils/CrmUtils';
import thanksSource from '../../assets/images/svg/thanks-img.svg'

export default function Contactus(props) {

//const { error } = props;
let partner_id = props.partner.partnerID ? props.partner.partnerID : '0';
const initialValues = {
    id: partner_id,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    comments: '',
    newsletter: ''
}

const labels = {
    first_name: 'שם פרטי',
    last_name: 'שם משפחה',
    email: 'אימייל',
    phone: 'טלפון',
    comments: 'הערה'
}

const [values, setValues] = useState(initialValues);
const [errors, setErrors] = useState({});
const [thanksMsg, setThanksMsg] = useState(false);

const handleChange = (e) => {
    if(e.target.name === 'newsletter'){
        var {name, checked} = e.target;
        setValues({...values,[name]: checked})
    }else{
        var {name, value} = e.target;
        setValues({...values,[name]: value})
        validate({[name]: value})
    }
}

const handleSubmit = async (e) => {
    try{
        let res = await CrmUtils.contactFormSubmit(e, validate(), values)
        res.data && setThanksMsg(true)
        window.scrollTo(0, 0)
    } catch(error){
        console.log(error)
    }
}


function validate(fieldValues = values){
    let tempObj = {...errors};
    if('first_name' in fieldValues){
        tempObj.first_name = fieldValues.first_name ? "" : ".";
    }
    if('last_name' in fieldValues){
        tempObj.last_name = fieldValues.last_name ? "" : ".";
    }
    if('phone' in fieldValues){
        tempObj.phone = fieldValues.phone.length > 8 ? "" : "חייב להכיל לפחות 9 ספרות";
    }
    if('email' in fieldValues){
        tempObj.email = (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(fieldValues.email) ? "" : "נא להזין כתובת אימייל חוקית";
    }
    if('comments' in fieldValues){
        tempObj.comments = fieldValues.comments ? "" : ".";
    }
    setErrors({...tempObj})
    if(fieldValues === values){
        return Object.values(tempObj).every(x => x === "");
    }
}

  return (
    <div id="main" role="main" className="relative">
        {thanksMsg && <div className="colored_block"></div>}
        <div className="contact-us" id="first-content-section">
            {!thanksMsg && <div className="content-container">
                <H1Title title="צור קשר"/>
                <div className="contact-form__wrapper">
                    <form onSubmit={handleSubmit} action="" method="" className="contact-form">
                        {
                            (errors?.first_name || errors?.last_name || errors?.email || errors?.phone || errors?.comments ) ? 
                            <p className="error-message">
                                הטופס לא נשלח משום שחלק מהפרטים חסרים: 
                                {
                                   Object.keys(errors)?.map((item, i) =>{                                 
                                        let x = (errors[item] !== '') ? 
                                                <span key={i}>  
                                                    {(errors[item] != '' && i != 0) ? ', ' : ''} {labels[item]}  
                                                </span> : 
                                                null
                                        return x;
                                   })
                                }
                            </p> 
                            : 
                            null
                        }
                        <p className="contact-form__title">מלאו את הפרטים ואנחנו ניצור קשר בהקדם.</p>
                        <Input 
                            type="text" 
                            label="*שם פרטי" 
                            name="first_name" 
                            error={errors?.first_name == "." ? true : false}
                            value={values.first_name}
                            onChange={handleChange}
                        />
                        <Input 
                            type="text" 
                            label="*שם משפחה" 
                            name="last_name" 
                            error={errors?.last_name == "." ? true : false} 
                            value={values.last_name}
                            onChange={handleChange}
                        />
                        <Input 
                            type="text" 
                            label="*טלפון" 
                            name="phone" 
                            error={errors?.phone} 
                            value={values.phone}
                            onChange={handleChange}
                        />
                        <Input 
                            type="text" 
                            label="*אימייל" 
                            name="email" 
                            error={errors?.email}  
                            value={values.email}
                            onChange={handleChange}
                        />
                         
                        <TextareaAutosize
                            className={ errors?.comments == "." ? 'textarea_error' : ''}
                            aria-label="minimum height"
                            minRows={5}
                            placeholder="*איך נוכל לעזור?"
                            name='comments'
                            error={errors?.comments} 
                            value={values.comments}
                            onChange={handleChange}
                        /> 
                        {(errors?.comments) && <p className='textarea_error helper_text'></p>}       
                        <div className="checkbox-wrapper"> 
                            <input type="checkbox" id="email-subscription" name='newsletter' onChange={handleChange}/> 
                            <label htmlFor="email-subscription">אשמח לקבל במייל הצעות ועדכונים מרוח טובה</label> 
                        </div> 
                        <input type="submit" value="שלח" className="btn btn-blue" />
                    </form>
                </div>
            </div>}
            {thanksMsg && <div className="thanks" id="first-content-section">
				<div className="thanks__content">
					<h2 role="alert"> <span>איזה כיף שבחרת להתנדב! </span> <br/> יועצי ההתנדבות שלנו יחזרו אליך להמשך התהליך. אם ייקח לנו כמה ימים זה בגלל שיש עוד אנשים טובים כמוך (: </h2>
					<div className="img-holder"> 
                        <img src={thanksSource} width="73" height="132px" alt="thanks image"/> 
                    </div> 
                    <a href="https://www.facebook.com/RuachTova" target="_blank" className="btn border-btn" tabIndex=""> הצטרפו לקהילת הפייסבוק שלנו </a>
				</div>
			</div>}
        </div>
    </div>
  )
}
