import React from 'react';
import AccountPersonalDetails from '../account-personal-details/AccountPersonalDetails';
import AccountProgressBlock from '../account-progress-block/AccountProgressBlock';
import AccountVolunteersFilter from '../account-volunteers-filter/AccountVolunteersFilter';
import './AccountParticipantTable.css';
import { DataGrid, heIL } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import AccordionBlock from '../accordion-block/AccordionBlock';
import {BrowserView, MobileView} from 'react-device-detect';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { he } from 'date-fns/locale'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import moment from "moment";

export default function AccountParticipantTable(props) {

    const [locale, setLocale] = React.useState('heIL');
    const theme = useTheme();
    theme.typography = {
        font: "14px 'AlmoniDL',sans-serif"
    };
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );

    const columns = [
        { 
            field: 'date', 
            headerName: 'תאריך', 
            width: 129, 
            headerClassName: 'info-label' 
        },
        { 
            field: 'from', 
            headerName: 'משעה', 
            width: 129, 
            headerClassName: 'info-label' 
        },
        { 
            field: 'to', 
            headerName: 'עד שעה', 
            headerClassName: 'info-label',
            width: 129, 
            overflow: 'hidden'
        },
        {
            field: 'activityTime',
            headerName: 'משך הפעילות',
            width: 129, 
            headerClassName: 'info-label',
        },
        {
            field: 'status',
            headerName: 'סטטוס',
            width: 129,
            headerClassName: 'info-label',
            
        },
        { 
            field: 'comments', 
            headerName: 'הערות', 
            headerClassName: 'info-label',
            width: 300       
        },
        {
            field: 'confirmation',
            headerName: 'אישור דיווח',
            headerClassName: 'info-label',
            sortable: false,
            renderCell: (params) => {
                return params.row.status === "ממתין לאישור" ? 
                <div className='reports-info__reporting-confirmation'>
                    <button className="btn" onClick={() => volunteerConfirmation(params.row.id)}>אישור</button>
                </div> : "";
            }
        }
    ];
    
    const rows = [
        { 
            id:1, 
            comments: "", 
            date: '14.02.22', 
            from: '13:00', 
            to: '21:00', 
            status: "ממתין לאישור",
            activityTime: "9 שעות"
        },
        { 
            id:2, 
            comments: "", 
            date: '14.02.22', 
            from: '12:00', 
            to: '14:00', 
            status: "ממתין לאישור",
            activityTime: "2 שעות"
        },
        { 
            id:3, 
            comments: "הצטרף לכת של הארי קרישנה", 
            date: '14.02.22', 
            from: '13:00', 
            to: '15:00', 
            status: "ממתין לאישור", 
            activityTime: "2 שעות"
        },
        { 
            id:4, 
            comments: "", 
            date: '14.02.22', 
            from: '14:00', 
            to: '18:00', 
            status: "ממתין לאישור",
            activityTime: "4 שעות"
        },
        { 
            id:5, 
            comments: "קבענו להיפגש לארבע שעות אבל בסוף שי לא יכלה להישאר שעתיים. אז נפגשנו רק שעתיים.", 
            date: '14.02.22', 
            from: '12:00', 
            to: '16:00', 
            status: "מאושר",
            activityTime: "4 שעות"
        },
        { 
            id:6, 
            comments: "", 
            date: '14.02.22', 
            from: '11:00', 
            to: '14:00', 
            status: "מאושר",
            activityTime: "3 שעות"
        },
        { 
            id:7, 
            comments: "", 
            date: '14.02.22', 
            from: '13:00', 
            to: '17:00', 
            status: "מאושר",
            activityTime: "4 שעות"
        }
    ];

    function volunteerConfirmation(row_id){
        console.log('confirm row_id_' + row_id);
    }

  return (
    <>

        <AccountProgressBlock 
            leading_color = {props.leading_color}
            progress_text = "בוצעו 8/34 שעות חונכות" 
            data_progress_value = "8" 
            data_max_value = "36"  
        />

        <BrowserView>
            <ThemeProvider theme={themeWithLocale}>
                <DataGrid
                    autoHeight {...rows}
                    sx={{ backgroundColor: '#fff', marginBottom: '20px'}}
                    rows={rows}
                    columns={columns}
                    pageSize={rows.length}
                    rowsPerPageOptions={[rows.length]}
                    localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                    disableSelectionOnClick
                    getCellClassName={(params) => {
                        if(params.field === 'status'){
                            if(params.row.status === 'מאושר'){
                                return "green-status";
                            }else if(params.row.status === 'ממתין לאישור'){
                                return "reports-status";
                            }else{
                                return '';
                            }
                        }
                    }}
                />
            </ThemeProvider>
        </BrowserView>
        
        <MobileView>
        {     
            rows?.map(function(item, i){
                return <AccordionBlock volunteerConfirmation={volunteerConfirmation} panel_num={"panel" + i} key={i} data={item}/>
            })
        }
        </MobileView>

   </>
  )

//   return (
//     <>
//         <AccountProgressBlock 
//             progress_text = "בוצעו 8/34 שעות חונכות" 
//             data_progress_value = "8" 
//             data_max_value = "36"  
//         />

//         <div className="reports-info">
//             <h2 className="title-with-line"> <span>יומן דיווחים</span> </h2>
//             <div className="info-labels reports-info__labels"> <strong className="info-label reports-info__date"> <span className="filtered-label">תאריך</span> </strong> <strong className="info-label reports-info__starting-hour"> <span>משעה</span> </strong> <strong className="info-label reports-info__ending-hour"> <span>עד שעה</span> </strong> <strong className="info-label reports-info__hours-count"> <span>משך הפעילות</span> </strong> <strong className="info-label reports-info__status"> <span className="filtered-label">סטטוס</span> </strong> <strong className="info-label reports-info__comment"> <span>הערות</span> </strong> <strong className="info-label reports-info__reporting-confirmation"> <span>אישור דיווח</span> </strong> </div>
//             <div className="reports-info__row info-row"> <button className="collapse-content-btn" aria-expanded="false" role="button">פתיחה או סגירה של האריח </button>
//                 <div className="reports-info__date info-item">
//                     <p className="reports-date">15/1/22</p>
//                 </div>
//                 <div className="reports-info__starting-hour info-item">
//                     <p className="reports-starting-hour">13:00</p>
//                 </div>
//                 <div className="reports-info__ending-hour info-item">
//                     <p className="reports-ending-hour">21:00</p>
//                 </div>
//                 <div className="reports-info__hours-count info-item">
//                     <p className="reports-hours-count">9 שעות</p>
//                 </div>
//                 <div className="reports-info__status info-item">
//                     <p className="reports-status">ממתין לאישור</p>
//                 </div>
//                 <div className="reports-info__reporting-confirmation info-item"> <button className="btn">אישור</button> </div>
//             </div>
//             <div className="reports-info__row info-row"> <button className="collapse-content-btn" aria-expanded="false" role="button">פתיחה או סגירה של האריח </button>
//                 <div className="reports-info__date info-item">
//                     <p className="reports-date">14.01.22</p>
//                 </div>
//                 <div className="reports-info__starting-hour info-item">
//                     <p className="reports-starting-hour">13:00</p>
//                 </div>
//                 <div className="reports-info__ending-hour info-item">
//                     <p className="reports-ending-hour">21:00</p>
//                 </div>
//                 <div className="reports-info__hours-count info-item">
//                     <p className="reports-hours-count">9 שעות</p>
//                 </div>
//                 <div className="reports-info__status info-item">
//                     <p className="reports-status">ממתין לאישור</p>
//                 </div>
//                 <div className="reports-info__reporting-confirmation info-item"> <button className="btn">אישור</button> </div>
//             </div>
//             <div className="reports-info__row info-row"> <button className="collapse-content-btn" aria-expanded="false" role="button">פתיחה או סגירה של האריח </button>
//                 <div className="reports-info__date info-item">
//                     <p className="reports-date">14.01.22</p>
//                 </div>
//                 <div className="reports-info__starting-hour info-item">
//                     <p className="reports-starting-hour">12:00</p>
//                 </div>
//                 <div className="reports-info__ending-hour info-item">
//                     <p className="reports-ending-hour">14:00</p>
//                 </div>
//                 <div className="reports-info__hours-count info-item">
//                     <p className="reports-hours-count">2 שעות</p>
//                 </div>
//                 <div className="reports-info__status info-item">
//                     <p className="reports-status">ממתין לאישור</p>
//                 </div>
//                 <div className="reports-info__reporting-confirmation info-item"> <button className="btn">אישור</button> </div>
//             </div>
//             <div className="reports-info__row info-row"> <button className="collapse-content-btn" aria-expanded="false" role="button">פתיחה או סגירה של האריח </button>
//                 <div className="reports-info__date info-item">
//                     <p className="reports-date">14.01.22</p>
//                 </div>
//                 <div className="reports-info__starting-hour info-item">
//                     <p className="reports-starting-hour">13:00</p>
//                 </div>
//                 <div className="reports-info__ending-hour info-item">
//                     <p className="reports-ending-hour">15:00</p>
//                 </div>
//                 <div className="reports-info__hours-count info-item">
//                     <p className="reports-hours-count">2 שעות</p>
//                 </div>
//                 <div className="reports-info__status info-item">
//                     <p className="reports-status">ממתין לאישור</p>
//                 </div>
//                 <div className="reports-info__comment info-item">
//                     <div className="reports-comment-wrapper">
//                         <p className="reports-comment"> הצטרף לכת של הארי קרישנה </p>
//                     </div>
//                 </div>
//                 <div className="reports-info__reporting-confirmation info-item"> <button className="btn">אישור</button> </div>
//             </div>
//             <div className="reports-info__row info-row"> <button className="collapse-content-btn" aria-expanded="false" role="button">פתיחה או סגירה של האריח </button>
//                 <div className="reports-info__date info-item">
//                     <p className="reports-date">14.01.22</p>
//                 </div>
//                 <div className="reports-info__starting-hour info-item">
//                     <p className="reports-starting-hour">14:00</p>
//                 </div>
//                 <div className="reports-info__ending-hour info-item">
//                     <p className="reports-ending-hour">18:00</p>
//                 </div>
//                 <div className="reports-info__hours-count info-item">
//                     <p className="reports-hours-count">4 שעות</p>
//                 </div>
//                 <div className="reports-info__status info-item">
//                     <p className="reports-status">ממתין לאישור</p>
//                 </div>
//                 <div className="reports-info__reporting-confirmation info-item"> <button className="btn">אישור</button> </div>
//             </div>
//             <div className="reports-info__row info-row"> <button className="collapse-content-btn" aria-expanded="false" role="button">פתיחה או סגירה של האריח </button>
//                 <div className="reports-info__date info-item">
//                     <p className="reports-date">14.01.22</p>
//                 </div>
//                 <div className="reports-info__starting-hour info-item">
//                     <p className="reports-starting-hour">12:00</p>
//                 </div>
//                 <div className="reports-info__ending-hour info-item">
//                     <p className="reports-ending-hour">16:00</p>
//                 </div>
//                 <div className="reports-info__hours-count info-item">
//                     <p className="reports-hours-count">4 שעות</p>
//                 </div>
//                 <div className="reports-info__status info-item">
//                     <p className="reports-status green-status">מאושר</p>
//                 </div>
//                 <div className="reports-info__comment info-item">
//                     <div className="reports-comment-wrapper">
//                         <p className="reports-comment"> קבענו להיפגש לארבע שעות אבל בסוף </p>
//                     </div>
//                 </div>
//             </div>
//             <div className="reports-info__row info-row"> <button className="collapse-content-btn" aria-expanded="false" role="button">פתיחה או סגירה של האריח </button>
//                 <div className="reports-info__date info-item">
//                     <p className="reports-date">14.01.22</p>
//                 </div>
//                 <div className="reports-info__starting-hour info-item">
//                     <p className="reports-starting-hour">11:00</p>
//                 </div>
//                 <div className="reports-info__ending-hour info-item">
//                     <p className="reports-ending-hour">14:00</p>
//                 </div>
//                 <div className="reports-info__hours-count info-item">
//                     <p className="reports-hours-count">3 שעות</p>
//                 </div>
//                 <div className="reports-info__status info-item">
//                     <p className="reports-status green-status">מאושר</p>
//                 </div>
//             </div>
//             <div className="reports-info__row info-row"> <button className="collapse-content-btn" aria-expanded="false" role="button">פתיחה או סגירה של האריח </button>
//                 <div className="reports-info__date info-item">
//                     <p className="reports-date">14.01.22</p>
//                 </div>
//                 <div className="reports-info__starting-hour info-item">
//                     <p className="reports-starting-hour">13:00</p>
//                 </div>
//                 <div className="reports-info__ending-hour info-item">
//                     <p className="reports-ending-hour">17:00</p>
//                 </div>
//                 <div className="reports-info__hours-count info-item">
//                     <p className="reports-hours-count">4 שעות</p>
//                 </div>
//                 <div className="reports-info__status info-item">
//                     <p className="reports-status green-status">מאושר</p>
//                 </div>
//             </div>
//         </div>

//         <AccountPersonalDetails 
//             title="פרטים אישיים" 
//             name="רוני זילברשטיין" 
//             email="ronizilberstein@gmail.com"
//             phone="052254326"
//         />
//    </>
//   )
}
