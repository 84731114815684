import React, { useEffect, useRef, useState } from 'react'
import './DashboardBox.css'
import CircularProgress from '@mui/material/CircularProgress'
import CrmUtils from '../../services/crm-utils/CrmUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupLoader, setSelectedLabel } from '../../services/store/GenericReducer';


function DashboardBox(props) {
	const store = useSelector(state => state.reducer)
	const [loader, setLoader] = useState(false)
	const leadingStyle = {borderTop: `6px solid ${props.leadingColor}`, color: props.leadingColor}
	const leadingStyleSVG = {
		 color: `${props.leadingColor}`
	}
	const dispatch = useDispatch()
	
	const getPopupData = async (click) => {
		dispatch(setPopupLoader(true))
		setLoader(true)
		try{
			let res = await CrmUtils.getPopupGraphInfo(props.reqUrl, props.chart, props.popupFilter, props.selectedDateRange[0].startDate, props.selectedDateRange[0].endDate)
			props.setPopupData(res.data)
			if((res.data.graphData && res.data.graphData.dataset) || (res.data.donutData && res.data.donutData.dataset)){ 
				click && props.clickOpen(true)
			}
		}catch(e){console.error(e)}
		dispatch(setPopupLoader(false))
		setLoader(false)
	}

	useEffect(() => {
		store.selectedLabel === props.index && getPopupData(false)
	}, [props.popupFilter, props.selectedDateRange])

    return (
		<div className="card" style={leadingStyle} onClick={() => {props.setSelectedLabel(props.index); getPopupData(true); dispatch(setSelectedLabel(props.index))}}>
			<div className="icon-large" style={leadingStyleSVG}> {props.icon} </div>
			<div className="card-text">
				<p className='card-desc'>{props.label}</p>
				<p className='label'> {loader && <span className='loadBoxSection'><CircularProgress sx={{color: props.loaderColor}}/></span>}{props.labelNumber}</p>
			</div>
		</div>
    )
}

export default DashboardBox;