export default function HoursSum() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
            <g fill="red" fillRule="nonzero">
                <circle cx="27.75" cy="15.779" r="1.632"/>
                <path d="M31.219 2.89h-1.88V1.446a1.445 1.445 0 1 0-2.89 0v1.446h-6.576V1.445a1.445 1.445 0 1 0-2.89 0v1.446h-6.504V1.445a1.445 1.445 0 1 0-2.891 0v1.446H5.78A5.788 5.788 0 0 0 0 8.67V31.22A5.788 5.788 0 0 0 5.781 37h11.057a1.445 1.445 0 1 0 0-2.89H5.78a2.894 2.894 0 0 1-2.89-2.891V8.672a2.894 2.894 0 0 1 2.89-2.89h1.807v1.445a1.445 1.445 0 1 0 2.89 0V5.78h6.504v1.446a1.445 1.445 0 1 0 2.891 0V5.78h6.576v1.446a1.445 1.445 0 1 0 2.89 0V5.78h1.88a2.894 2.894 0 0 1 2.89 2.89v8.24a1.445 1.445 0 1 0 2.891 0V8.67a5.788 5.788 0 0 0-5.781-5.78z"/>
                <path d="M28.294 19.588c-4.8 0-8.706 3.906-8.706 8.706 0 4.8 3.906 8.706 8.706 8.706 4.8 0 8.706-3.905 8.706-8.706 0-4.8-3.905-8.706-8.706-8.706zm0 14.534a5.835 5.835 0 0 1-5.828-5.828 5.835 5.835 0 0 1 5.828-5.828 5.835 5.835 0 0 1 5.828 5.828 5.835 5.835 0 0 1-5.828 5.828z"/>
                <path d="M29.982 26.695h-.71v-1.41c0-.742-.706-1.344-1.577-1.344s-1.577.602-1.577 1.344v2.754c0 .742.706 1.343 1.577 1.343h2.287c.87 0 1.577-.601 1.577-1.343s-.706-1.344-1.577-1.344z"/>
                <circle cx="21.221" cy="15.779" r="1.632"/>
                <circle cx="15.779" cy="21.221" r="1.632"/>
                <circle cx="9.25" cy="15.779" r="1.632"/>
                <circle cx="9.25" cy="21.221" r="1.632"/>
                <circle cx="9.25" cy="27.75" r="1.632"/>
                <circle cx="15.779" cy="27.75" r="1.632"/>
                <circle cx="15.779" cy="15.779" r="1.632"/>
            </g>
        </svg>
    )
}