import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import Input from '../../components/input/Input';
import LoginErrorMsg from '../../components/login-error-message/LoginErrorMsg';
import Cookies from 'universal-cookie';
import {isMobile} from 'react-device-detect';
import CrmUtils from '../../services/crm-utils/CrmUtils';
import FooterLogoRuachtova from '../../svg/FooterLogoRuachtova';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../../services/store/GenericReducer';

export default function Login(props) {

	const [error, setError] = useState({username: false, password: false});
	const [errorMessage, setErrorMessage] = useState("");
	const [submitLoader, setSubmitLoader] = useState(false);
	const navigate = useNavigate();
	const login_details = (typeof(props.partner.partnerConfig.loginType.loginDetails) != 'undefined') ? 
	JSON.parse(props.partner.partnerConfig.loginType.loginDetails) : [];
	const popupBorderColor = `7px solid ${props.partner.partnerConfig.leadingColor}`;	

	const handleErrors = () => {
		setError(function(prevState){
			return { ...prevState, username: true, password: true }
		})
	}

	const hendleResponse = (token) => {
		const cookies = new Cookies();
		cookies.set("ruach", token, { path: '/', maxAge: 24 * 60 * 60 });
		props.manageToken(token);
		let lastUrl = cookies.get("ruach_redirect_url");
		if(lastUrl){
			cookies.remove("ruach_redirect_url", {path: '/'})
			navigate(lastUrl);
		}else{
			navigate(`/${props.partner.partnerLink}/`);
		}
	}

	const onFormSubmit = async (e) => {
		setSubmitLoader(true)
		e.preventDefault();
		const data = new FormData(e.target);
		let req_data = {
			username: data.get('username'),
			password: data.get('password'),
			scope: props.partner.partnerID
		}

		if(req_data.username !== "" && req_data.password !== "") {
			try {
				let res = await CrmUtils.loginFormSubmit(req_data)
				hendleResponse(res.data.access_token)
			} catch (error) {
				if ('response' in error) {
					console.log(error.response.data.error.error_description)
					setErrorMessage(error.response.data.error.error_description)
					handleErrors()
				}
			}
		}else{
			setErrorMessage("נא למלא שדות")
		}
		setSubmitLoader(false)
	}

  return (
        <div id="main" role="main">
			<div className="login">
				<div className="login__popup" style={{borderTop: (isMobile) ? 'none' : popupBorderColor, borderBottom: (isMobile) ? popupBorderColor : 'none'}}>
					<div className="login__info">
						<div className="login__text-info">
							<h2>{props.partner.partnerConfig.authTitle}</h2>
							{/* <p>{props.partner.partnerConfig.authMessage}</p> */}
							{/* <p>לאחר ההתחברות, יחכו לך מגוון התנדבויות שאפשר להירשם אליהם אחרי בקשת אישור מהמנהל ליציאה להתנדבות. בסיום התהליך תגיע הודעת אישור מ-CBC NEWS עם פרטי ההתנדבות שבחרת.</p>  */}
                            <p>{props.partner.partnerConfig.authMessage}</p>
							{/* <!-- can be more than 1 paragraph --> */}
						</div>
						<div className="login__form-info">
							<div className="login__form-title">
								<h2>{props.partner.partnerConfig.loginTitle}</h2>
								<p>{props.partner.partnerConfig.loginSubtitle}</p>
							</div>
							<form onSubmit={onFormSubmit} className="login__form error-form">
								{ errorMessage ? <LoginErrorMsg error_message={errorMessage} /> : null}
								{(login_details.length > 0) ? 
								login_details.map(function(item, i){
									let input_label = `*${item.label}`;
									return <Input key={i} type={item.type} className='' label={input_label} id={item.name} name={item.name} error={error[item.name]}/>
								}) : 'לא קיימים שדות'}                               
								<button type="submit" className="btn btn-blue relative">
									כניסה
									{submitLoader && <CircularProgress className="btn_spinner" sx={{color: "#fff"}} />}
								</button>
							</form>
						</div>
					</div>
					{props.partner.partnerConfig && props.partner.partnerConfig.logoUrl && <div className="login__bottom-block">
						{/* <!-- if no client logo, block login__client-logo shouldn't be displayed--> */}
						<div className="login__client-logo"> 
                            <img src={props.partner.partnerConfig.logoUrl} className="header_logo" width="177px" height="31px" alt="shop logo"/> 
                        </div>
						<div className="login__logo"> 
							<FooterLogoRuachtova/>
                        </div>
					</div>}
				</div>
			</div>
		</div>
  )
}
