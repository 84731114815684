import React, { useState } from 'react'
import './FilterBranches.css'


export default function FilterBranches() {

    const [showDropDown, setShowDropDown] = useState(false);

  return (
    <div onClick={() => setShowDropDown(!showDropDown)} className={`field-wrapper dropdown branches-dropdown ${showDropDown && 'show'}`}> 
        <label htmlFor="branches" className="sr-only">קמפוסים / סניפים</label>
        <div className="input-with-icon"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30">
                <path d="M18.287 1.261a2.897 2.897 0 0 0-2.403 0L1.6 7.772a1.576 1.576 0 0 0 0 2.895l4.526 2.064v7.447c0 1.26.817 2.4 2.192 3.315v1.693a2.192 2.192 0 1 0 1.096 0v-1.064a17.96 17.96 0 0 0 7.67 1.535c6.145 0 10.958-2.407 10.958-5.48v-7.446l4.527-2.064a1.576 1.576 0 0 0 0-2.895L18.287 1.261zm-8.324 26.04a1.096 1.096 0 1 1-2.192 0 1.096 1.096 0 0 1 2.192 0zm-2.74-7.123V13.23l1.096.5v8.413a2.674 2.674 0 0 1-1.096-1.965zm19.724 0c0 2.376-4.516 4.383-9.862 4.383-3.06 0-5.845-.658-7.67-1.669V14.23l6.469 2.948c.763.349 1.64.349 2.403 0l8.66-3.948v6.948zm5.169-10.509-14.283 6.512a1.825 1.825 0 0 1-1.494 0l-6.207-2.83 4.832-2.445a4.396 4.396 0 0 0 2.121.505c1.843 0 3.288-.962 3.288-2.191 0-1.23-1.445-2.192-3.288-2.192-1.843 0-3.287.963-3.287 2.192.003.323.102.637.285.903L8.855 12.77l-6.8-3.1a.481.481 0 0 1 0-.9l14.283-6.512a1.824 1.824 0 0 1 1.494 0L32.116 8.77a.481.481 0 0 1 0 .9zm-17.222-.45c0-.517.937-1.095 2.191-1.095 1.255 0 2.192.578 2.192 1.096 0 .517-.937 1.095-2.192 1.095-1.254 0-2.191-.578-2.191-1.095z" fill="#298CCA" fillRule="nonzero" stroke="#298CCA" strokeWidth=".2" />
            </svg> 
            <input type="text" id="branches" className="branches-input" placeholder="קמפוסים / סניפים" readOnly={true}/> 
            <span className="dropdown-arrow"></span> 
        </div>
        <div className="dropdown-content">
            <div className="branches-container  list-container">
                <ul className="branches-list">
                    <li data-param-name="branch"> <a href="#">אוניברסיטת תל אביב</a> </li>
                    <li data-param-name="branch"> <a href="#">הבין תחומי הרצליה</a> </li>
                    <li data-param-name="branch"> <a href="#">המכללה האקדמית קרית אונו</a> </li>
                    <li data-param-name="branch"> <a href="#">הטכניון</a> </li>
                    <li data-param-name="branch"> <a href="#">שנקר</a> </li>
                    <li data-param-name="branch"> <a href="#">בצלאל</a> </li>
                    <li data-param-name="branch"> <a href="#">אוניברסיטת בר אילן</a> </li>
                    <li data-param-name="branch"> <a href="#">בצלאל</a> </li>
                    <li data-param-name="branch"> <a href="#">מכון היט חולון</a> </li>
                    <li data-param-name="branch"> <a href="#">אוניברסיטת ויצו חיפה</a> </li>
                </ul>
            </div>
        </div>
    </div>
  )
}
