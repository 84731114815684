import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Typography from '@mui/material/Typography';

export default function AccordionBlockDashboard(props) {

    // id:1,
    // employeeName:'Jaimy',
    // employeeId: 12,
    // projectName: 'ruah',
    // date: '12-08-03',
    // numOfHoursPoints: 1,
    // actualHours:2,
    // status: 'active',
    // dateRegister: '01-08-2022'
    const status_classes = `reports-status ${props.data.status === 'מאושר' ? 'green-status' : ''}`;
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography>
                    <span className="mobile-report-preview collapsed-content">
                        <span className="reports-date">{props.data.date}</span>
                        <span className="reports-hours-count">{props.data.employeeName}</span>
                        <span className={status_classes}>{props.data.status}</span>
                    </span>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <span className="reports-info__row info-row" style={{ display: 'block' }}>
                        <span className="reports-info__comment info-item">
                            <strong className="info-label"> <span>שם עובד</span> </strong>
                            <span className={status_classes}>{props.data.employeeName}</span>
                        </span>
                        <span className="reports-info__comment info-item">
                            <strong className="info-label"> <span>שם פרויקט</span> </strong>
                            <span className={status_classes}>{props.data.projectName}</span>
                        </span>
                        <span className="reports-info__starting-hour info-item">
                            <strong className="info-label reports-info__starting-hour"> <span>משעה</span> </strong>
                            <span className="reports-starting-hour">{props.data.date}</span>
                        </span>
                        <span className="reports-info__starting-hour info-item">
                            <strong className="info-label reports-info__starting-hour"> <span>שעות</span> </strong>
                            <span className="reports-starting-hour">{props.data.actualHours}</span>
                        </span>

                        <span className="reports-info__starting-hour info-item">
                            <strong className="info-label reports-info__starting-hour"> <span>נקודות</span> </strong>
                            <span className="reports-starting-hour">{props.data.numOfHoursPoints}</span>
                        </span>

                        <span className="reports-info__status info-item">
                            <strong className="info-label reports-info__status"> <span>מצב</span> </strong>
                            <span className={status_classes}>{props.data.status}</span>
                        </span>
                    </span>
                </Typography>

            </AccordionDetails>
        </Accordion>
    )
}