import React from 'react';
import './AccountVolunteersFilter.css';

export default function AccountVolunteersFilter() {

  return (
    <div className="dropdown instructor-reports__filtration-dropdown" role="button" aria-expanded="false"> <label htmlFor="instructor-reports__filtration">instructor reports filtration</label>
      <div className="dropdown-input"> <input type="text" id="instructor-reports__filtration" className="instructor-reports__input" readOnly={true}/> <span className="dropdown-arrow"></span> </div>
      <div className="dropdown-content">
        <div className="instructor-reports__list">
          <ul>
            <li> <a href="#">שלומי ברכה</a> </li>
            <li> <a href="#">אהוד בנאי</a> </li>
            <li> <a href="#">ורד יופי חינללאוועה</a> </li>
            <li> <a href="#">ארז ברטסלבה</a> </li>
            <li> <a href="#">דינה לילה טוב</a> </li>
            <li> <a href="#">רוקי אריקסון</a> </li>
            <li> <a href="#">לארס אולריץ׳</a> </li>
            <li> <a href="#">ארצ׳י חינאווי באנקר</a> </li>
          </ul>
          <ul>
            <li> <a href="#">קורקי סאו</a> </li>
            <li> <a href="#">ורד מרד</a> </li>
            <li> <a href="#">ברוך קרא</a> </li>
            <li> <a href="#">יוסי נצתינה</a> </li>
            <li> <a href="#">הילה הרשקוביץ</a> </li>
            <li> <a href="#">קורי חלומי</a> </li>
            <li> <a href="#">משה אופנקייס</a> </li>
            <li> <a href="#">שלומו גרוניף</a> </li>
          </ul>
          <ul>
            <li> <a href="#">גורדי שלומייצקוביץ</a> </li>
            <li> <a href="#">קוקוריקו שמואל</a> </li>
            <li> <a href="#">ביצי מפרועחה</a> </li>
            <li> <a href="#">פרפר לאנחמד</a> </li>
            <li> <a href="#">גרגמל שדרסדר</a> </li>
            <li> <a href="#">צ׳יקו ארד</a> </li>
            <li> <a href="#">יצחק אחרונסיקי</a> </li>
            <li> <a href="#">צביקה פיקובר</a> </li>
          </ul>
        </div>
      </div>
    </div> 
  )
}