import { Box, ClickAwayListener, OutlinedInput, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { he } from 'date-fns/locale'
import { DateRange } from "react-date-range";
import { format } from 'date-fns';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';



import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


import './DashboardDatePicker.css'
export default function DashboardDatePicker(props) {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      props.active && setOpen((prev) => !prev);
    };
  
    const handleClickAway = () => {
      setOpen(false);
    };

    const stylesDateRange = {
        position: 'absolute',
        top: 30,
        right: 0,
        left: 0,
        zIndex: 1,
        p: 1,
      };
      const stylesDateRangeField = {
        margin: '2px 2px 2px 0', width: '238px', height: '33px', fontSize: 14, textAlign: 'right', position: 'relative', direction:'ltr',
        "&.MuiInputBase-root": { 
            "& > input": {
                border: "unset",
                textAlign: "right",
                padding: "0 5px",
                color: '#000000',
                letterSpacing: '0.08px',
                fontFamily: 'AlmoniDL'
            },
            "& > fieldset": {
                "& > legend":{
                    display: 'none'
                }
            }
         }
      };
      const selectedDate = props.selectedDateRange[0].endDate && props.selectedDateRange[0].startDate ? `${format(props.selectedDateRange[0].endDate, 'dd.MM.yyyy')} - ${format(props.selectedDateRange[0].startDate, 'dd.MM.yyyy')}` : ''
      //console.log("here ->> ", selectedDate)
      return (
        <ThemeProvider theme={props.theme}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={he}
            >
                <div className='datePicker'>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Box id='box-datepicker' sx={{ position: 'relative', textAlign: 'right', fontFamily: 'AlmoniDL' }}>
                            <OutlinedInput
                                variant="outlined"
                                id="adornment"
                                size="small"
                                value={selectedDate}
                                sx={stylesDateRangeField}
                                startAdornment={<CalendarMonthIcon fontSize='large' sx={{color: '#e2e2e2', }} />}
                                onClick={handleClick}
                                disabled={!props.active}
                            />
                            {open ? (
                                <Box sx={stylesDateRange}>
                                        <DateRange
                                            id='dashboard-date-range'
                                            className="myDateRange"
                                            onChange={(item) => props.setSelectedDateRange([item.selection])}
                                            ranges={props.selectedDateRange}
                                            months={2}
                                            locale={he}
                                            direction="horizontal"
                                            preventSnapRefocus={true}
                                            showDateDisplay={false}
                                        />
                                </Box>
                            ) : null}
                        </Box>
                    </ClickAwayListener>
                </div>
            </LocalizationProvider>
        </ThemeProvider>
    )
}

