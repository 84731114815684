import React, { useState } from 'react'
import './FilterAmount.css'


export default function FilterAmount() {

    const [showDropDown, setShowDropDown] = useState(false);

  return (
    <div onClick={() => setShowDropDown(!showDropDown)} className={`field-wrapper dropdown how-many-of-you-dropdown ${showDropDown && 'show'}`}> <label htmlFor="how-many-of-you" className="sr-only">כמה אתם?</label>
        <div className="input-with-icon"> <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="27" height="27" viewBox="0 0 27 27">
                <defs>
                    <path id="6l9br2ubfa" d="M0 0h27v27H0z" />
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="grg8j2saqb" fill="#fff">
                        <use xlinkHref="#6l9br2ubfa" />
                    </mask>
                    <path d="M21.022 1.46a3.712 3.712 0 0 0-3.707 3.707 3.711 3.711 0 0 0 3.707 3.708 3.712 3.712 0 0 0 3.708-3.708 3.712 3.712 0 0 0-3.708-3.708zm-11.2 7.405a3.712 3.712 0 0 0-3.707 3.708 3.712 3.712 0 0 0 3.708 3.708 3.712 3.712 0 0 0 3.707-3.708 3.712 3.712 0 0 0-3.707-3.708zM2.898 27a2.865 2.865 0 0 1-2.339-1.19 2.867 2.867 0 0 1-.416-2.59c.91-2.833 3.18-5.17 6.073-6.254l.537-.201-.423-.389a5.182 5.182 0 0 1-1.673-3.803 5.173 5.173 0 0 1 5.167-5.166 5.148 5.148 0 0 1 4.794 3.243l.169.42.366-.266a9.95 9.95 0 0 1 2.275-1.236l.532-.205-.42-.385a5.179 5.179 0 0 1-1.683-3.811A5.172 5.172 0 0 1 21.022 0a5.172 5.172 0 0 1 5.167 5.167c0 1.45-.618 2.844-1.695 3.823l-.422.383.531.206c.735.287 1.437.66 2.085 1.113a.73.73 0 0 1-.835 1.196A8.446 8.446 0 0 0 21 10.366c-2.228 0-4.331.854-5.922 2.405l-.095.092-.01.132a5.146 5.146 0 0 1-1.678 3.401l-.422.384.531.206c.734.286 1.435.66 2.085 1.113a.727.727 0 0 1-.418 1.328.724.724 0 0 1-.416-.132 8.446 8.446 0 0 0-4.855-1.522c-3.753 0-7.153 2.423-8.269 5.893-.141.442-.066.91.207 1.285.273.375.696.59 1.16.59h9.414a.73.73 0 0 1 0 1.459H2.897z" fill="#298CCA" mask="url(#grg8j2saqb)" />
                    <path d="M21.13 1.095a3.957 3.957 0 0 0-3.953 3.953 3.957 3.957 0 0 0 3.952 3.953 3.957 3.957 0 0 0 3.953-3.953 3.957 3.957 0 0 0-3.953-3.953zM9.77 8.607a3.957 3.957 0 0 0-3.952 3.953 3.957 3.957 0 0 0 3.952 3.953 3.957 3.957 0 0 0 3.952-3.953A3.957 3.957 0 0 0 9.77 8.607zM2.745 27A2.716 2.716 0 0 1 .53 25.872a2.716 2.716 0 0 1-.394-2.455C1.04 20.6 3.3 18.274 6.178 17.196l.838-.315-.659-.605a5.062 5.062 0 0 1-1.635-3.716A5.053 5.053 0 0 1 9.77 7.512a5.03 5.03 0 0 1 4.685 3.17l.263.654.57-.415a9.916 9.916 0 0 1 2.264-1.23l.83-.32-.656-.599a5.062 5.062 0 0 1-1.644-3.724A5.053 5.053 0 0 1 21.13 0a5.054 5.054 0 0 1 5.048 5.048 5.06 5.06 0 0 1-1.656 3.735l-.657.598.828.322a9.889 9.889 0 0 1 2.074 1.107.548.548 0 0 1-.627.898 8.755 8.755 0 0 0-5.034-1.579 8.75 8.75 0 0 0-6.14 2.494l-.148.143-.017.206a5.027 5.027 0 0 1-1.638 3.323l-.658.598.828.322c.73.284 1.428.656 2.074 1.107a.543.543 0 0 1 .136.762.55.55 0 0 1-.45.235.544.544 0 0 1-.312-.099 8.756 8.756 0 0 0-5.034-1.579c-3.888 0-7.412 2.513-8.57 6.11a1.631 1.631 0 0 0 .238 1.476c.314.43.8.678 1.331.678h9.55a.548.548 0 0 1 0 1.095h-9.55zM22.5 27a.687.687 0 0 1-.686-.686v-3.128h-3.128a.687.687 0 0 1 0-1.372h3.128v-3.128a.687.687 0 0 1 1.372 0v3.128h3.128a.687.687 0 0 1 0 1.372h-3.128v3.128A.687.687 0 0 1 22.5 27" fill="#298CCA" mask="url(#grg8j2saqb)" />
                    <path d="M22.5 27a.529.529 0 0 1-.528-.528v-3.444h-3.444a.529.529 0 0 1 0-1.056h3.444v-3.444a.529.529 0 0 1 1.056 0v3.444h3.444a.529.529 0 0 1 0 1.056h-3.444v3.444A.53.53 0 0 1 22.5 27" fill="#298CCA" mask="url(#grg8j2saqb)" />
                </g>
            </svg> <input type="text" id="how-many-of-you" className="how-many-of-you" placeholder="כמה אתם?" readOnly={true}/> <span className="dropdown-arrow"></span> </div>
        <div className="dropdown-content">
            <div className="how-many-of-you-container list-container">
                <ul className="how-many-of-you-list">
                    <li data-param-name="how-many-count"> <a href="#">יחידים</a> </li>
                    <li data-param-name="how-many-count"> <a href="#">יחידים וקבוצות</a> </li>
                    <li data-param-name="how-many-count"> <a href="#">קבוצות</a> </li>
                </ul>
            </div>
        </div>
    </div>
  )
}
