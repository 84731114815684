import React from 'react';

import AccountInstractorTableEmpty from '../account-instractor-table-empty/AccountInstractorTableEmpty';
import AccountInstractorTable from '../account-instractor-table/AccountInstractorTable';
import './AccountInstractor.css';

export default function AccountInstractor(props) {

  const leadingColor = props.partner.partnerConfig.leadingColor;
  const isContent = true;  
  const displayContent = isContent ? <AccountInstractorTable leadingColor={leadingColor}/> : <AccountInstractorTableEmpty/>;
  
  return (    
    <>
        {displayContent}
    </>
  )
}
