import React, { useEffect, useState } from 'react'
import FaqSubject from '../../components/faq-subject/FaqSubject'
import H1Title from '../../components/h1-title/H1Title'
import H2TitleWithLine from '../../components/h2-title-with-line/H2TitleWithLine'

import Projects from '../../components/projects/Projects'
import './Faq.css'
import CrmUtils from '../../services/crm-utils/CrmUtils'
import { useSelector } from 'react-redux'
import { Box, LinearProgress } from '@mui/material'

export default function Faq() {

    const store = useSelector(state => state.reducer)
    const [faqsArray, setFaqsArray] = useState(false)

    const getFaqs = async () => {
        try{ 
            let res = await CrmUtils.getStaticPage(4)
            console.log(res.data)
            setFaqsArray(res.data)
        }catch(e){console.error(e)}   
    }

    useEffect(() => {
        getFaqs()
    }, [])

  return (
    <div id="main" role="main">
    <section className="faq" id="first-content-section">
        <div className="content-container">
            <div className="faq__list">      
                <H1Title title={faqsArray?.title}/>
                {
                    faqsArray && faqsArray?.staticPages?.map(item => {
                        return <FaqSubject key={item.staticPageID} staticPageItem={item}/>
                    })
                }
            </div>
        </div>
    </section>

    <section className="card-section cards-slider">
        <div className="content-container">
            
            {/* <h2 className="title-with-line"><span>אולי יעניין אותך </span></h2>
                <div className="card-wrapper">
                    {     		
                        store.moreProjects?.length > 0 ? <Projects partner={props.partner} leadingColor={partnerColor} projects={store.moreProjects}/> : 
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    }
                </div>
            <div className="footer-section-link-wrapper"> <a href="index-column-mobile-card" className="footer-section-link"> הצג עוד </a> </div> */}

        </div>
    </section>
</div>
  )
}
