import React, { useEffect, useRef, useState } from 'react'
import './Privacy.css'
import CrmUtils from '../../services/crm-utils/CrmUtils'

export default function Privacy() {

  const [privacy, setPrivacy] = useState(false)

  const getPrivacyPage = async () => {
    try{ 
        let res = await CrmUtils.getStaticPage(2)
        Array.isArray(res.data.staticPages) && 
        setPrivacy(res.data.staticPages[0].rawHTML) 
    }catch(e){console.error(e)}   
  }

  let finalMount = useRef(true)
  useEffect(() => {
    if(finalMount.current){
      finalMount.current = false
      getPrivacyPage()
    }
  }, [])

  return (
    <div id="main" role="main">
        <div dangerouslySetInnerHTML={{ __html: privacy }} className="text-page" id="first-content-section">
        {/* <div className="text-page" id="first-content-section">
            
            <div className="content-container">
                <h1>מדיניות פרטיות</h1> <span className="text-page-date">אפריל , 2020</span>
                <h3>1. מדיניות פרטיות</h3>
                <p><strong>1.1</strong>&nbsp;רוח טובה ע"ר (&nbsp;<strong>"רוח טובה"</strong>&nbsp;או&nbsp;<strong>"החברה"</strong>), מכבדת את פרטיותך. כאשר אתה משתמש בשירותים שלנו, אתה בוטח בנו במידע שלך. אנו מבינים שזו אחריות גדולה ומשקיעים מאמצים רבים כדי להגן על המידע שלך.</p>
                <p><strong>1.2</strong>&nbsp;מטרת מדיניות זו להסביר לך בצורה שקופה מהו הבסיס לעיבוד כל מידע אישי שנאסף ממך באמצעות גלישתך באתר האינטרנט בכתובת&nbsp;<Link to="https://www.ruachtova.org.il/">www.ruachtova.org.il</Link>&nbsp;(<strong>״האתר״</strong>), באיזה אופן ישמש המידע, אופן גילוי המידע כפי שנמסר על ידך לצדדים שלישיים והשימוש ב-"עוגיות" או בקבצים או תגיות באתר האינטרנט.</p>
                <p><strong>1.3</strong>&nbsp;החברה מחויבת להגן על המידע האישי שלך (כהגדרתו להלן) באופן העולה בקנה אחד עם חוקי הגנת המידע התקפים. נבקשך לקרוא בעיון את מדיניות זו בצירוף תנאי השימוש של החברה, ולהבין את עמדותיה ונהליה של החברה בנוגע למידע האישי שלך ולאופן שבו החברה תנהג בו.</p>
                <p><strong>1.4</strong>&nbsp;תנאי מדיניות הפרטיות המפורטים בלשון זכר הינם לשם נוחיות בלבד והיא מיועדת לשני המינים כאחד.</p>
                <h3>2. אישור המדיניות על ידי המשתמש</h3>
                <p>שימושך באתר על ידי רישום, גלישה או כניסה לאתר מהווים אישור מצדך כי קראת את מדיניות הפרטיות ואת נהלי החברה המפורטים בה, וכי אתה מסכים לכך שהחברה תעשה שימוש במידע האישי שלך (מידע אודות אדם), לרבות עיבוד (כולל איסוף, שימוש, גילוי, החזקה או העברה) של מידע על פי תנאי מדיניות זו. המידע המוחזק בידי החברה אודותיך עשוי להיות מוחזק ומעובד באמצעות קבצי מחשב ו/או במסמכים בכתב.</p>
                <p>במידה שתמסור לחברה כל מידע אודות אדם אחר, עליך להפנות את תשומת לבו של אדם זה כאמור למדיניות פרטיות זו.</p>
                <p>לידיעתך, אינך חייב לפי חוק לספק מידע אישי, אך אם לא תספק אותו לא תוכל ליהנות מהאתר. אם אינך מסכים לתנאים שלהלן אנא אל תעשה כל שימוש באתר.</p>
                <h3>3. סוגי המידע הנאסף</h3>
                <p><strong>3.1. הגדרות</strong></p>
                <p><strong>מידע אישי –&nbsp;</strong>הינו כל מידע ביחס לענייניו הפרטיים של אדם ו/או מידע אודות אדם מזוהה או הניתן לזיהוי; אדם הניתן לזיהוי הוא אדם אשר ניתן לזהותו בין במישרין ובין בצירוף עם נתונים נוספים שבידינו או שיש לנו גישה אליהם לדוגמא: שם מלא, כתובת דוא"ל, מספר טלפון, מספר זיהוי, נתוני מיקום, או עם גורם הנוגע לזהות הפיזית, הפיזיולוגית, הגנטית, הנפשית, הכלכלית, התרבותית או החברתית של אותו אדם.</p>
                <p><strong>מידע אנונימי –</strong>&nbsp;הנו מידע שאינו מאפשר לחברה לפנות אליך או לזהותך, ואינו קשור למידע שניתן להשתמש בו לצורך כך. מידע זה כולל מידע שנאסף באופן פאסיבי על פעילותך באתר האינטרנט כגון נתוני שימוש (usage data ) במידה שמידע כאמור אינו מקושר למידע האישי שלך.</p>
                <p>פירוט אודות סוגי המידע הנאסף ראה סעיפים 3.3 ו-3.4 להלן.</p>
                <p><strong>3.2.&nbsp;</strong>לתשומת לבך, באפשרותך לבחור שלא למסור לחברה מידע מסוים (לדוגמה, באמצעות שינוי הגדרות העוגיות שלך, אנא ראה סעיף 3.4.2) הבחירה אם למסור לחברה כל מידע נתונה להחלטתך, אולם אם לא תעשה כן, תיתכן הגבלה על מאפייני האתר בהם תוכל להשתמש.</p>
                <p><strong>3.3. מידע הנמסר מרצון&nbsp;</strong>מידע הנמסר לחברה מיוזמתך במהלך שימושך באתר הנאסף על ידינו.<br/>
                להלן דוגמאות:</p>
                <ul>
                <li>מידע אישי שתזין בעת שימושך באתר או רישומך לרשימת התפוצה של החברה הכולל לדוגמא את שמך המלא, כתובת דוא"ל, מספר טלפון וכו'.</li>
                <li>תכתובות עם החברה – החברה עשויה לשמור תכתובות שנערכו בינך לבינה לצרכי שירות לקוחות, ניהול ושיפור האתר.<br/>
                בכל עת תוכל לצאת מרשימת התפוצה ו/או לבקש את הסרתך על ידי שליחת בקשה באימייל לכתובת&nbsp;<Link to="mailto:info@ruachtova.org.il">info@ruachtova.org.il</Link></li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>3.4. מידע שנאסף באמצעות טכנולוגיה</strong></p>
                <ul>
                <li><strong>3.4.1 קובצי יומן רישום –&nbsp;</strong>לצרכי תחזוקה שגרתית, ניתוח נתוני תעבורה ומתן ושיפור האתר, אנחנו משתמשים בקובצי יומן רישום שאוספים מידע כגון כתובות פרוטוקול אינטרנט (IP), מידע על דפדפנים, עוגיות, ספקי שירות אינטרנט (ISP), דפים מפנים/דפי יציאה, סוגי פלטפורמות, חותמות תאריך/שעה ומספר ומיקום בדף של לחיצות. אנחנו משתמשים במידע זה כדי לנתח מגמות, לנהל את האתר, לעקוב אחר תנועת משתמשים באופן מצטבר, לאבחן בעיות בשרתים שלנו ולאסוף מידע דמוגרפי נרחב לשימוש מצטבר. מידע זה אינו מקושר למידע נוסף המאפשר זיהוי אישי באמצעים סבירים. אנחנו משתמשים גם בכלי שירות למעקב המשתמש בקובצי יומן רישום כדי לנתח תנועות משתמשים ולשמור כתובות IP לצרכים סטטיסטיים אגרגטיביים.</li>
                <li><strong>3.4.2 עוגיות (Cookies) וווב ביקונס (Web Beacons) –</strong>
                <ul>
                <li>במטרה לאפשר שימוש בפונקציות מסוימות, אנו משתמשים בעוגיות בדפים שונים באתר האינטרנט שלנו, וכן בקבצים, אשר הינם מספקים חיצוניים (כמו גוגל, פייסבוק) ובתגיות כגון JavaScript. בעקבות אינטראקציות שלך עם כפתורים והפניות לספקים חיצוניים (למשל לחיצה על "like" של פייסבוק) הספק עשוי לאסוף אודותיך מידע בהתאם למדיניות הפרטיות של אותו ספק חיצוני.</li>
                <li>עוגיות הם פיסות מידע קטנות המאוחסנות במחשב שלך וקשורות למידע עליך. חלק מהעוגיות המשמשות אותנו יימחקו מהכונן הקשיח לאחר סיום גלישתך בדפדפן (עוגיות זמניות). וחלק מהעוגיות נשמרות בכונן הקשיח של מחשבך או של מכשיר הטלפון הסלולרי שלך, ומאפשרות לנו לזהות את מכשירך בביקורים עתידיים באתר האינטרנט שלנו (עוגיות קבועות) (Persistent Cookies).</li>
                <li>העוגיות יעקבו, בין היתר, אחר הקלקות ופעילות מקוונת.</li>
                <li>מטעם זה, אתר האינטרנט מכיל עוגיות וקבצים מקומיים אחרים, על מנת להבדיל בינך ובין משתמשים אחרים. החברה רשאית להשתמש בעוגיות או בקובץ קונפיגורציה, המאוחסן בדפדפן או בכונן הקשיח של מחשבך או של מכשיר הטלפון הסלולרי שלך, על מנת להשיג מידע אודות שימושך הכללי באינטרנט. עוגיות וקבצים אחרים מכילים מידע המועבר למחשבך ו/או לכונן הקשיח במכשיר הטלפון הנייד שלך. הם מסייעים לחברה לשפר את האתר ולספק שירות משופר ומותאם אישית. כמו כן הם מאפשרים לחברה למנוע הונאות ו/או שימוש לרעה.</li>
                <li>הבסיס החוקי לעיבוד המידע הנמצא בעוגיות ובקבצים או בתגיות אחרות מורכב מביצוע ההסכם עמך (על פיו עוגיות נחוצות כחלק מחוויית השימוש של הלקוח), או מהאינטרסים הלגיטימיים של החברה לאבטחת אתר האינטרנט שלה והצגתו באופן ידידותי למשתמש.</li>
                <li>באפשרותך לחסום עוגיות באמצעות הפעלת הגדרות מסוימות בדפדפן, המאפשרות לך לסרב להצבת עוגיות באופן מלא או באופן חלקי. עם זאת, אם תבחר הגדרות מחמירות מדי, עשויה לא להיות לך גישה לחלקים מסוימים באתר.</li>
                <li>פונקציית ה-"עזרה" בתפריט במרבית הדפדפנים כוללת הסבר לגבי אופן מניעת קבלת עוגיות בדפדפן (באופן מלא או באופן חלקי), אופן מחיקת כל העוגיות שנאספו ואופן חסימת העוגיות עתידיות.</li>
                <li>&nbsp;כמו כן, החברה עשויה לעשות שימוש באתר ובמיילים שלנו בווב ביקונס בכדי לאסוף את המידע המפורט במדיניות זו. ווב ביקונס, אלו הן תמונות אלקטרוניות שמאפשרות לנו לשלוח עוגיות, לנטר את השימוש שלך באתר או לקבל אינדיקציה לכך שבוצעה פעולה בתכתובת דואר אלקטרוני מטעמנו.</li>
                </ul>
                </li>
                </ul>
                <p>&nbsp;</p>
                <h3>4. כלי ניתוח מידע וטכנולוגית אינטרנט</h3>
                <p>אתר אינטרנט של החברה עושה שימוש במערכות ניתוח אינטרנטי לצורך ניטור, שיווק, פרסום ואחרות (כגון: Google Analytics ). מערכות אלו חשופות למידע האנונימי בלבד ולא למידע האישי (כהגדרתם בסעיף 3.1 לעיל).</p>
                <h3>5. נתוני רצף הקלקות (CLICKSTREAM DATA)</h3>
                <p>במהלך גלישה באינטרנט, נותר שובל של מידע אלקטרוני בכל אתר אינטרנט בו ביקרת. מידע זה, המכונה לעתים נתוני רצף הקלקותClickstream Data) ), עשוי להיאסף ולהישמר בשרת אתר האינטרנט. נתוני רצף ההקלקות מספקים לחברה מידע אודות סוג המחשב ותכנת הדפדפן, כתובת אתר האינטרנט ממנה הגעת לאתר החברה, ובמקרים מסוימים, כתובת הדוא”ל שלך. אנו רשאים להשתמש בנתוני רצף הקלקות על מנת לקבוע את משך הזמן בו שוהים גולשים בכל דף באתר האינטרנט ואופן גלישתם באתר. מידע זה ישמש אותנו לצורך שיפור והתאמה אישית של אתר האינטרנט בלבד.</p>
                <h3>6. שימוש ושיתוף מידע</h3>
                <p><strong>6.1 –&nbsp;</strong>החברה תשתמש במידע האישי שלך אך ורק לצורך לשמו מסרת את המידע מיוזמתך (כגון: בקשתך להצטרף לרשימת תפוצה, בקשתך למציאת מקום להתנדבות וכו'). כמו כן, החברה עשויה גם לעשות שימוש במידע לצורך ניתוח נתונים וסטטיסטיקות ולצורך ציות לחוק ובירור תלונות, חשד להונאה או התנהגות בלתי הולמת.</p>
                <p><strong>6.2 העברת מידע לגורמים שלישיים –&nbsp;</strong>אף על-פי שאנו עושים מאמצים לשמור על פרטיותך, ייתכן שנידרש על-פי חוק לחשוף מידע שסיפקת בעת שימושך באתר.<br/>
                כמו כן, ייתכן ונשתף את המידע האישי שלך במקרה ונאמין בתום לב כי יש בכך צורך לשם שמירה על עניין שלנו או של צד שלישי הנוגע לביטחון לאומי, לאכיפת החוק, ליטיגציה, חקירה פלילית, הגנה על בטיחותו של כל אדם או כדי למנוע מוות או נזק גופני קרוב, בתנאי שאנו סבורים כי אינטרס זה אינו מתבטל על ידי האינטרסים שלך או זכויות היסוד והחירויות הדורשות הגנה על הנתונים האישיים שלך.<br/>
                בנוסף, ייתכן גם שנחשוף מידע על אנשים המאיימים ו/או עשויים לפגוע בנו ובשירותינו (כגון הונאה מצד משתמשים, שליחת איומים, הפצת דואר זבל וכו'). כמו כן, החברה רשאית לשתף את המידע האישי שלך כנדרש להפעלה, תחזוקה ושיפור האתר.</p>
                <p><strong>6.3 קבלני משנה וספקי שירות –&nbsp;</strong>אנחנו עשויים לשתף את המידע שלך עם ספקי שירות וקבלני משנה העובדים עמנו ו/או המספקים שירותים מסוימים מטעמנו בארץ ובחו"ל וזאת ככל שהדבר יידרש לצורך מתן השירות, שיפורו ופיתוח שירותים נוספים. כמו כן, אנו עשויים לאחסן מידע בשירותי ענן ששרתיו ממוקמים מחוץ לישראל. &nbsp;חברות אלה אינן רשאיות לשתף או להשתמש בעצמן במידע המאפשר זיהוי אישי שאנחנו מספקים שלא עבורנו או לצורך תפעול האתר. בנוסף, אנחנו עשויים להעסיק גורמי צד שלישי מהימנים כדי לסייע בהפצת סקרים, פרסום ו/או תכנים. לאחר השלמת פרויקטים אלה, אנו דורשים כי כל המידע יוחזר אלינו. לבסוף, אנחנו משתפים מדי פעם מידע אגרגטיבי עם ספקי השירות שלנו. מידע זה אינו מכיל מידע המאפשר זיהוי אישי באמצעים סבירים והוא משמש לפיתוח תוכניות ותוכן שעשויים לעניין את המבקרים באתר האינטרנט שלנו.</p>
                <h3>7. מיזוג ורכישה</h3>
                <p>החברה רשאית להמחות ולהסב זכויותיה והתחייבויותיה, לרבות העברת המידע שלך, במקרה של מכירה ו/או מיזוג בו מועברת השליטה בחברה ו/או בעיקר נכסיה ו/או באתר לצד שלישי כלשהו ובלבד שאותו צד שלישי יכנס בנעלי החברה לכל דבר ועניין הקשור בהוראות מדיניות זו.</p>
                <h3>8. אבטחת ושמירת מידע</h3>
                <p><strong>8.1&nbsp;</strong>החברה נוקטת צעדים סבירים כפי הנהוג בתעשייה כדי לסייע בהגנה על אבטחת הנתונים האישיים שלך. החברה תפעל בהתאם לדין החל במקרה של כל פגיעה באבטחה, בסודיות או בשלמות המידע האישי שלך, ובמידה שנראה לנכון או אם נידרש על פי הדין החל, נמסור לך הודעה באמצעות דוא”ל, במסרון או באמצעות פרסום בולט באתר האינטרנט, בהקדם האפשרי וללא דיחוי בלתי סביר, ככל שהדבר עולה בקנה אחד עם (1) צרכים לגיטימיים של אכיפת חוק או (2) כל אמצעי הנחוץ לצורך קביעת היקף ההפרה ולהשבתה על כנה של שלמות מערכת המידע.</p>
                <p><strong>8.2&nbsp;</strong>אף שלא ניתן לערוב לאבטחת המידע באינטרנט או מחוץ לו, אנו עושים מאמצים סבירים מבחינה מסחרית לאסוף את המידע ולאבטחו בהתאם למדיניות הפרטיות ולכל החוקים והתקנות החלים.</p>
                <p><strong>8.3&nbsp;</strong>החברה מגבילה את הגישה למידע האישי שלך לאנשים הזקוקים לגישה כאמור על מנת לעבדו עבורה או לשם ביצוע עבודה ספציפית (כגון: נציג שירות לקוחות), אנשים אלו יהיו כפופים להסכמי סודיות. יחד עם זאת, יש לקחת בחשבון ששום אמצעי ו/או שירות אינם בטוחים באופן מוחלט, במיוחד כשהפעילות היא מקוונת.</p>
                <p><strong>8.4&nbsp;</strong>אם יש לך שאלות כלשהן בנוגע לאבטחת המידע שלך, אתה מוזמן לשלוח הודעה לכתובת הדואר האלקטרוני הרשומה מטה.</p>
                <h3>9. שלמות המידע</h3>
                <p>אנו בודקים את נהלים שלנו לאיסוף, שמירה ועיבוד מידע כדי לוודא שאנו אוספים, שומרים ומעבדים רק את המידע האישי הדרוש כדי להפעיל או לשפר את האתר. אנו נוקטים צעדים סבירים כדי להבטיח שהמידע האישי שאנו מעבדים הוא מדויק, שלם ועדכני, אך אנו תלויים במשתמשים שלנו לעדכון או לתיקון המידע האישי שלהם בכל עת. אין במדיניות פרטיות זו דבר המתפרש כחובה לשמירת מידע, ואנו רשאים, לפי שיקול דעתנו, למחוק או להימנע מאיסוף ושמירה של כל מידע.</p>
                <h3>10. קישורים</h3>
                <p>שים לב שאתר האינטרנט שלנו כולל קישורים שמובילים לאתרים אחרים. אתרים אלה עשויים לאסוף ממך מידע המאפשר זיהוי אישי. מדיניות פרטיות זו אינה כוללת את נוהלי איסוף המידע או המדיניות של אתרי אינטרנט כאלה ו/או כל גורם שלישי, וכל שימוש שתעשה בקישורים ו/או שירותים אלה הינו באחריותך בלבד.</p>
                <h3>11. זכויותיך</h3>
                <p>בכפוף לדין החל, עשויות לעמוד לך הזכויות הבאות בנוגע למידע אישי, כולן או חלקן:</p>
                <p><strong>11.1&nbsp;</strong>גישה למידע האישי שלך: עומדת לך הזכות לקבל מידע ולבקש גישה לנתונים האישים שאנו מעבדים &nbsp;אודותיך;</p>
                <p><strong>11.2&nbsp;</strong>זכות לתיקון – הזכות לבקש מהחברה לתקן או לעדכן את הנתונים האישיים שלך כאשר הם אינם מדויקים או לא שלמים;</p>
                <p><strong>11.3&nbsp;</strong>הזכות למחיקה – הזכות לבקש שנמחק את הנתונים האישיים שלך;</p>
                <p><strong>11.4&nbsp;</strong>הזכות להגביל – הזכות לבקש מהחברה להפסיק באופן זמני או לצמיתות לעבד את כל הנתונים האישיים שלך או חלקם;</p>
                <p><strong>11.5&nbsp;</strong>זכות להתנגד או לבטל הסכמה – הזכות להתנגד לעיבוד הנתונים האישיים שלך מטעמים בקשורים למצבך או להתנגד לעיבוד הנתונים למטרות שיווקיות.</p>
                <p><strong>11.6&nbsp;</strong>כל זכות אחרת הקבועה בחוק הגנת הפרטיות, התשמ"א-1981 ובתקנות שהותקנו מכוחו.</p>
                <p><strong>11.7&nbsp;</strong>במידה וברצונך לממש את זכויותיך כמצוין לעיל, נבקשך לשלוח אימייל לכתובת הדואר האלקטרוני הבאה:&nbsp;<Link to="mailto:info@ruachtova.org.il">info@ruachtova.org.il</Link>&nbsp;עם פרטים שיאפשרו לנו לאמת את זהותך.</p>
                <h3>12. אכיפה</h3>
                <p>החברה בוחנת באופן שוטף קבוע את עמידתה במדיניות פרטיות זו. אל תהסס לפנות אלינו בכל שאלה בנוגע למדיניות פרטיות זו או בנוגע לטיפול שלנו במידע אישי, בכתובת כמפורט להלן. כאשר אנו מקבלים תלונות בכתב, מדיניות החברה היא ליצור קשר עם המשתמש מתלונן. נשתף פעולה עם הרשויות הרגולטוריות המתאימות, לרבות רשויות מקומיות העוסקות באבטחת מידע, כדי לטפל בכל תלונה בנוגע להעברת מידע אישי שלא ניתן לפתור בין החברה לבין יחיד.</p>
                <h3>13. ילדים</h3>
                <p>החברה אינה אוספת ביודעין מידע אישי מזהה מילדים מתחת לגיל 18. על המשתמשים מתחת לגיל 18 לקבל הסכמת הורה או משמורן לצפייה באתר. אם ייוודע לנו כי ילד מתחת לגיל 18 מסר מידע אישי מזהה באתר, נעשה מאמצים סבירים להסיר מידע זה מתיקיות האתר. שירותי החברה אינם מופנים לילדים מתחת לגיל 18 והחברה אינה מעוניינת לקבל מידע אישי מילדים. במידה והינך סבור שנמסר לחברה מידע על ידי ילד אנא פנה אלינו לכתובת&nbsp;<Link to="mailto:info@ruachtova.org.il">info@ruachtova.org.il</Link></p>
                <h3>14. הסכמה לעיבוד מידע אישי</h3>
                <p>על ידי מסירת מידע האישי לחברה בהתאם למדיניות פרטיות זו, כל משתמשי האתר, לרבות, אך לא רק, משתמשים בארה"ב, בישראל ובמדינות החברות באיחוד האירופי, מבינים ומביעים את הסכמתם המפורשת למדיניות פרטיות זו ולאיסוף ועיבוד מידע אישי כאמור בחו"ל. השרת שעליו נמצא האתר עשוי להיות מחוץ למדינה שממנה ניגשת לאתר ועשוי להיות מחוץ לארץ המגורים שלך. חלק מהשימושים הנזכרים במדיניות פרטיות זו עשויים להיות כרוכים בהעברת המידע האישי שלך למדינות שונות ברחבי העולם, שלהן עשויות להיות רמות שונות של הגנה על פרטיות ממדינתך. על ידי אספקת מידע אישי שלך באמצעות האתר, אתה מסכים ומאשר כי אנו רשאים לאסוף, להשתמש, להעביר ולחשוף את המידע האישי שלך כפי שמתואר במדיניות פרטיות זו. הנך מאשר בזאת כי הסכמתך ניתנת באופן חופשי ולא בשל חובה חוקית.</p>
                <h3>15. שינויים במדיניות הפרטיות</h3>
                <p>אנו רשאים לשנות את מדיניות הפרטיות בכל עת. במקרה של שינוי נוהלי הפרטיות שלנו, נשנה מסמך זה כך שתוכל לדעת איזה מידע אנחנו אוספים, כיצד אנחנו עשויים להשתמש בו ובפני מי אנחנו עשויים לחשוף אותו. נודיע לך על כל שינוי מהותי במדיניות פרטיות זו על-ידי פרסום הודעה בולטת באתר האינטרנט שלנו ובמקרה שנרצה להחיל שינוי זה גם על מידע שכבר מסרת לנו גם נשלח לך הודעה במטרה לבקש את הסכמתך לכך. מומלץ לעיין בהצהרה זו מדי פעם כדי לקבל מידע על האופן שבו אנחנו מגנים על המידע שלך.</p>
                <h3>16. יצירת קשר</h3>
                <p>תודה שקראת את מדיניות הפרטיות שלנו. אם יש לך שאלות בנוגע למדיניות זו, אנא פנה למנהל אבטחת המידע ( Data Protection Officer) של רוח טובה בכתובת&nbsp;<Link to="mailto:info@ruachtova.org.il">info@ruachtova.org.il</Link></p>  
            </div> */}

        </div>
    </div>
  )
}
