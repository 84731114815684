import React from 'react'
import './AccordionBlock.css'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function AccordionBlock(props) {

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const status_classes = `reports-status ${props.data.status === 'מאושר' ? 'green-status' : ''}`;

function kob(bb){
    console.log(bb);
}

  return (
    <Accordion expanded={expanded === props.panel_num} onChange={handleChange(props.panel_num)}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
        >
            <Typography> 
            <span className="mobile-report-preview collapsed-content"> 
                <span className="reports-date">{props.data.date}</span>
                <span className="reports-hours-count">{props.data.activityTime}</span>
                <span className={status_classes}>{props.data.status}</span>
            </span>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
            <span className="reports-info__row info-row" style={{display: 'block'}}> 
                <span className="reports-info__date info-item">
                    <strong className="info-label reports-info__date"> 
                        <span className="filtered-label">תאריך</span> 
                    </strong>
                    <span className="reports-date">{props.data.date}</span>
                </span>
                <span className="reports-info__starting-hour info-item">
                    <strong className="info-label reports-info__starting-hour"> <span>משעה</span> </strong>
                    <span className="reports-starting-hour">{props.data.from}</span>
                </span>
                <span className="reports-info__ending-hour info-item">
                    <strong className="info-label reports-info__ending-hour"> <span>עד שעה</span> </strong>
                    <span className="reports-ending-hour">{props.data.to}</span>
                </span>
                <span className="reports-info__hours-count info-item">
                    <strong className="info-label reports-info__hours-count"> <span>משך הפעילות</span></strong>
                    <span className="reports-hours-count">{props.data.activityTime}</span>
                </span>
                <span className="reports-info__status info-item">
                    <strong className="info-label reports-info__status"> <span className="filtered-label">סטטוס</span> </strong>
                    <span className={status_classes}>{props.data.status}</span>
                </span>
                {props.data.comments ? 
                (<span className="reports-info__comment info-item">
                    <strong className="info-label reports-info__comment"> <span>הערות</span> </strong>
                    <span className="reports-comment-wrapper">
                        <span className="reports-comment">{props.data.comments}</span>
                    </span>
                </span>) : null}

                {props.data.status === "ממתין לאישור" && typeof(props.volunteerConfirmation) !== 'undefined' ? 
                (<span onClick={() => props.volunteerConfirmation(props.data.id)} className="reports-info__reporting-confirmation info-item">
                    <strong className="info-label reports-info__reporting-confirmation"> 
                    <span>אישור דיווח</span> 
                    </strong> 
                    <button type='button' className="btn">אישור</button> 
                </span>) : null}
                 
            </span>
            </Typography>
        </AccordionDetails>
    </Accordion>
  )
}
