import React, { useEffect } from 'react';
import AccountMenu from '../../components/account-menu/AccountMenu';
import { Outlet, useLocation } from 'react-router-dom';
import './Account.css';
import { useState } from 'react';

export default function Account(props) {

let instructor_reports_path = `${props.match}/instructor-reports`;
const [headerBorder, setHeaderBorder] = useState(true) 
const leadingColor = props.partner.partnerConfig.leadingColor;
const location = useLocation()

  return (
    <div id="main" role="main" className='main-account'>
      <div className="volunteering-title">
				{headerBorder && <div className="content-container">
					<h1>התנדבות</h1>
				</div>}
			</div>
        <div className="volunteering" id="first-content-section">
            <div className="content-container">
                {/* <AccountMenu leading_color={leadingColor}/> */}
                <Outlet/>
            </div>
        </div>  
    
    </div>
  )
}
