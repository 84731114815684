import React from 'react';
import './FaqItem.css';

export default function FaqItem(props) {
  return (
    <div className="faq__item">
        <h3>{props.question_title}</h3>
        <p>{props.question}</p>
        <h4>{props.answer_title}</h4>
        <p>{props.answer}</p>
    </div>
  )
}
