import React from 'react';
import { Grid } from '@mui/material';
import './DashboardLabelBlock.css'
import LoveIcon from '../../assets/images/svgComp/LoveIcon';
import ListBIcon from '../../assets/images/svgComp/ListBIcon';

export default function DashboardLabelBlock(props) {
    const leadingStyle = {
        color: `${props.leadingColor}`
   }
    return (

        <div className='instructor-reports__top-block'>
            {
                props.managerInfo?.tilesData?.map((item, index) => {
                    return  <Grid item xs className='block' key={index}>
                                <span className='icon-label'>
                                    {index > 0 ? <LoveIcon/> : <ListBIcon/>}
                                </span>
                                <span className='label-text'>{item.label}</span>
                                <div className='label-number'>{item.infoData}</div>
                            </Grid>
                })
            }
        </div>

    )

}