import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import './assets/js/main.js';
import './assets/css/all.css';
import './assets/css/client-style.css';
import './assets/font-awesome/css/font-awesome.css';
import React, { useEffect, useRef, useState } from 'react';
import Footer         from './components/footer/Footer';
import Header         from './components/header/Header';
import Accessibility  from './pages/accessibility/Accessibility';
import Account        from './pages/account/Account';
import Contactus      from './pages/contactus/Contactus';
import Dashboard      from './pages/dashboard/Dashboard';
import Faq            from './pages/faq/Faq';
import Home           from './pages/home/Home';
import Login          from './pages/login/Login';
import Privacy        from './pages/privacy/Privacy';
import Terms          from './pages/terms/Terms';
import AccountVolunteering from './components/account-volunteering/AccountVolunteering';
import AccountInstractor from './components/account-instractor/AccountInstractor';
import AccountParticipant from './components/account-participant/AccountParticipant';
import AccountPractice from './components/account-practice/AccountPractice';
import ErrorPage from './pages/error_page/ErrorPage';
import checkConnected from './services/check-connected/checkConnected';
import Loader from './components/loader/Loader';
import logout from './services/logout/logout';
import axios from 'axios';
import logoutAndSaveUrl from './services/logout/logoutAndSaveUrl';
import ProjectContent from './pages/ProjectContent/ProjectContent';
import CrmUtils from './services/crm-utils/CrmUtils';
import {clearAll, setHeaderMainLink, setRdxPartner, setUserDetails} from './services/store/GenericReducer.js';
import { useDispatch } from 'react-redux';
import Cookies from "universal-cookie";

function App() {

  const [showHeaderFooter, setShowHeaderFooter] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [token, setToken] = useState(checkConnected())
  const [partner, setPartner] = useState({})
  const [partnerReq, setPartnerReq] = useState(true)
  const [errorPageRedirect, setErrorPageRedirect] = useState(false)
  const genericBlue = "#2a8cca"
  const location = useLocation()
  const partnerName = location.pathname.split("/")[1]
  const dispatch = useDispatch()
  
  const getMainPartner = async(first_login, partner_id) => {
      setErrorPageRedirect(false);
      try{
        let partner = await CrmUtils.getPartner(first_login, partner_id)
        if(partner.data && ((partnerName.toLowerCase()) === (partner.data.partnerLink.toLowerCase()))){
          setPartner(partner.data)
        }else{
          if(first_login){
            setErrorPageRedirect(true)
            logout(partnerName, dispatch, false)
          }else{
            logout(partnerName, dispatch, true)
          }
        }
      } catch (error){
        setPartnerReq(false)
        if(error.response){
          console.log(error.response.status)
          first_login ? setErrorPageRedirect(true) : logout(partnerName, dispatch, true);
        }
      }
  }

  useEffect(() => {
    if(errorPageRedirect){
      const cookies = new Cookies();
      cookies.remove("ruach_redirect_url", {path: '/'})
    }
  }, [errorPageRedirect])

  useEffect(() => {
      dispatch(setRdxPartner(partner))
  }, [partner])

  useEffect(() => {
    locationTest() && dispatch(setHeaderMainLink(true))
  }, [location])

  const homeTest = () => {
    return location.pathname.toLowerCase() === partner.partnerLink.toLowerCase()
  }

  const locationTest = () => {
    return (location.pathname.includes('contactus') || location.pathname.includes('faq') ||
       location.pathname.includes('account') || location.pathname.includes('dashboard') ||
       location.pathname.includes('privacy') || location.pathname.includes('project') ||
       location.pathname.includes('accessibility') || location.pathname.includes('contract'))
  }

  const getUserDetails = async () => {
    try{
      let res = await CrmUtils.getUserAccountDetails()
      setUserInfo(res.data)
      dispatch(setUserDetails(res.data))
    }catch(error){
      console.log(error)
    }
  }

  let finalMount = useRef(true)
  useEffect(() => {
    if(finalMount.current){
      finalMount.current = false
      // Check for existing token
      if(token){
        //get partner json from api
        manageToken(token);
        getMainPartner(false, partnerName);
      }else{
        if(window.location.pathname !== `/${partnerName}/login`){
          // If not in login page  
          setErrorPageRedirect(false);
          if(partnerName){
            logoutAndSaveUrl(partnerName);
          }
        }else{
          // Login with admin details, server to server req
          getMainPartner(true, partnerName);
        }
      }
    }
  },[])

  // Set token and continue to app
   const manageToken = async (token) => {
    setShowHeaderFooter(true);
    setToken(token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] && getUserDetails()
  }, [axios.defaults.headers.common['Authorization']])

  return (
    <>
    { ("partnerID" in partner) ? 
	    <div className="wrapper main-page">
        {showHeaderFooter ? <Header partner={partner} getUserDetails={getUserDetails} userInfo={userInfo}/> : null}
        
        <Routes>
          <Route path={`/${partnerName}/login`} element={ <Login partner={partner} manageToken={manageToken}/> } />
          <Route path={`/${partnerName}/`} element={ <Home partner={partner}/> } />
          <Route path={`/${partnerName}/contactus`} element={ <Contactus partner={partner}/> } />
          <Route path={`/${partnerName}/faq`} element={ <Faq/> } />
          <Route path={`/${partnerName}/account`} element={ <Account partner={partner}/> }>
                <Route path="" element={ <AccountVolunteering partner={partner}/> } />
                <Route path="instructor-reports" element={ <AccountInstractor partner={partner}/> } />
                <Route path="participant-reports" element={ <AccountParticipant partner={partner}/> } />
                <Route path="practice" element={ <AccountPractice partner={partner}/> } />
          </Route>
          <Route path={`/${partnerName}/dashboard`} element= {<Dashboard partner={partner}/> } />
          <Route path={`/${partnerName}/accessibility`} element={ <Accessibility/> } />
          <Route path={`/${partnerName}/contract`} element={ <Terms/> } />
          <Route path={`/${partnerName}/privacy`} element={ <Privacy/> } />
          <Route path={`/${partnerName}/project/:id`} element={ <ProjectContent partner={partner} userInfo={userInfo}/> } />
          <Route path={`/${partnerName}/error`} element={ <ErrorPage/>} />
          <Route path={`/${partnerName}/*`} element={ <ErrorPage/>} />
        </Routes>

        {/* <Thankyou title="איזה כיף שבחרת להתנדב!" msg="יועצי ההתנדבות שלנו יחזרו אליך להמשך התהליך. אם ייקח לנו כמה ימים זה בגלל שיש עוד אנשים טובים כמוך (:
        "/> */}
        {showHeaderFooter ? <Footer partner={partner} token={token}/> : null}
      </div>  
     : (errorPageRedirect) ? <ErrorPage/> : partnerName ? partnerReq ? <Loader showLoader={true} color={genericBlue}/> : <h2 className="center error_page">חנות לא פעילה</h2> : <h2 className="center error_page">אנא הכנס מזהה עסק</h2>
    }
    </>
  );
}

export default App;

