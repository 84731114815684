import React from 'react';
import './AccountProgressBlock.css';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export default function AccountProgressBlock(props) {

  const dataProgressValue = props.dataProgressValue
  const dataMaxValue = props.dataMaxValue
  const progress_percent = 100 - ((dataProgressValue/dataMaxValue) * 100)
  const progress = `translateX(${progress_percent}%) !important`
  const leading_color = props.leading_color
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      transform: progress,
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? leading_color : '#308fe8',
    },
  }));

  return (
    <div className="event-progress-block">
				<p>{props.progress_text}</p>
				{/* <div id="volunteering__event-progress" className="event-progress" data-progress={props.data_progress_value} data-max-value={props.data_max_value}></div> */}
        <Box sx={{ flexGrow: 1 }}>
          <BorderLinearProgress variant="determinate" value={0} />
        </Box>
    </div>  
  )
}