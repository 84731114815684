import React from 'react';
import AccountPracticeTableEmpty from '../account-practice-table-empty/AccountPracticeTableEmpty';
import AccountPracticeTable from '../account-practice-table/AccountPracticeTable';
import './AccountPractice.css';

export default function AccountPractice(props) {

  const is_content = true;  
  const leadingColor = props.partner.partnerConfig.leadingColor;
  const display_content = is_content ? <AccountPracticeTable leading_color={leadingColor}/> : <AccountPracticeTableEmpty/>;
  
  return (    
    <>
        {display_content}
    </>
  )
}
