import React from 'react';
import './BannerText.css';
import {BrowserView, MobileView} from 'react-device-detect';

export default function BannerText(props) {

	const bunner_lead_color_border = `18px solid ${props.color}`;
	const bunner_lead_color_border_mobile = `4px solid ${props.color}`;

  return (
    <div className="alert">
		<div className="content-container">
			<BrowserView>
				<p style={{borderRight: bunner_lead_color_border}}>{props.content}</p>
			</BrowserView>
			<MobileView>
				<p style={{borderTop: bunner_lead_color_border_mobile}}>{props.content}</p>
			</MobileView>
		</div>
	</div>
  )
}
