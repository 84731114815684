import Cookies from 'universal-cookie';

export default async function logoutAndSaveUrl(partnerName) {
	let lastUrl = window.location.pathname;
	const cookies = new Cookies();
	cookies.set("ruach_redirect_url", lastUrl, { path: `/${partnerName}/` });
	if(partnerName){
		window.location.href = `/${partnerName}/login`; 
	}

}
