import Cookies from 'universal-cookie';
import {clearAll} from "../store/GenericReducer";

export default async function logout(partnerName, dispatch, redirect) {
	const cookies = new Cookies()
	cookies.remove("ruach_redirect_url", {path: '/'})
	cookies.remove("ruach", {path: '/'})
	dispatch(clearAll())
	if(redirect){
		window.location.href = `/${partnerName}/login`;
	}
}
