export default function PopulationIcon() {
    return  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28">
                <g fill="#298CCA" fillRule="evenodd">
                    <path d="m9.41 19.706 1.374-1.372h7.836c.3-.006 1.016-.117 1.016-1.015 0-.965-.846-1.014-1.016-1.014l-5.465-.044-6.258-2.275H.487v-1.45l6.785.044 6.258 2.276h5.09c.99 0 2.466.656 2.466 2.463 0 1.809-1.475 2.465-2.466 2.465h-7.236l-.949.948-1.025-1.026z" />
                    <path d="m.91 22.15.005-1.595 10.26 5.325c5.397-2.74 13.33-6.791 13.874-7.135a1.01 1.01 0 0 0 .441-.631 1.034 1.034 0 0 0-.112-.762.967.967 0 0 0-.601-.459.914.914 0 0 0-.736.116L19.486 19.5v-1.306l3.834-2.425a2.265 2.265 0 0 1 1.788-.284 2.35 2.35 0 0 1 1.459 1.114c.326.558.424 1.218.274 1.858a2.447 2.447 0 0 1-1.07 1.526c-.788.5-12.915 6.656-14.295 7.356l-.307.156L.909 22.15z" />
                    <path d="m21.075 16.765-1.27-.087c.01-.175.015-.352.015-.53 0-4.973-3.775-9.02-8.416-9.02-3.736 0-7.062 2.685-8.09 6.529l-1.222-.375c1.182-4.426 5.012-7.517 9.312-7.517 5.342 0 9.688 4.658 9.688 10.383 0 .207-.005.412-.017.617" />
                    <path d="M13.312 5.331c.862.345 2.515.878 4.124.68a4.723 4.723 0 0 0 1.063-.253c1.895-.704 3.022-2.66 3.486-3.647-.861-.344-2.516-.877-4.124-.68a4.744 4.744 0 0 0-1.065.254c-1.895.704-3.02 2.659-3.484 3.646zm4.103 2.111c-2.58.317-5.093-1.02-5.198-1.077l-.504-.273.176-.531c.05-.152 1.266-3.722 4.236-4.853a5.675 5.675 0 0 1 1.322-.322c2.582-.317 5.094 1.02 5.2 1.077l.504.272-.177.533c-.05.151-1.269 3.721-4.238 4.851a5.644 5.644 0 0 1-1.321.323z" />
                    <path d="m12.876 4.86 5.1-2.14.657.672-5.45 2.141zM0 11h1.4v13H0z" />
                </g>
            </svg> 
}