export default function SinglesAndGroups() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" href="http://www.w3.org/1999/xlink" width="31" height="38" viewBox="0 0 31 38">
            <defs>
                <path id="7bi5sddrqa" d="M0 0h31v37.643H0z"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="bnaajrfqhb" fill="#fff">
                    <use href="#7bi5sddrqa"/>
                </mask>
                <path d="M13.651 2.829c-3.07 0-5.57 2.489-5.57 5.548 0 3.06 2.5 5.549 5.57 5.549 3.072 0 5.57-2.49 5.57-5.549 0-3.06-2.498-5.548-5.57-5.548zm0 13.925c-4.636 0-8.409-3.758-8.409-8.377C5.242 3.758 9.015 0 13.652 0c4.636 0 8.409 3.758 8.409 8.377 0 4.62-3.773 8.377-8.41 8.377zM1.42 37.643c-.783 0-1.42-.635-1.42-1.415v-8.703c0-3.66 2.989-6.637 6.662-6.637h10.157v2.83H6.662c-2.108 0-3.822 1.707-3.822 3.807v7.29h8.736v2.828H1.42zm24.463-14.135c-.6 0-1.166.231-1.593.65l-7.471 7.427v3.23h3.241l7.435-7.425c.43-.422.67-.988.673-1.59a2.24 2.24 0 0 0-.653-1.602l-.021-.021c-.427-.43-1-.67-1.611-.67zM15.399 37.643c-.783 0-1.42-.635-1.42-1.415v-5.222A1.423 1.423 0 0 1 14.391 30l7.862-7.832a5.137 5.137 0 0 1 3.63-1.487c1.369 0 2.659.528 3.631 1.488 1.982 1.993 1.982 5.238-.002 7.233l-7.862 7.832c-.267.263-.622.409-1 .409H15.4z" fill="red" mask="url(#bnaajrfqhb)"/>
            </g>
        </svg>
    )
}