import axios from 'axios';

const getItems = (url) =>
{
    return axios.get(url)
}

const getItem = (url, id) =>
{
    return axios.get(url + "/" + id)
}

const getItemPost = (url, obj) =>
{
    return axios.post(url,obj)
}

const addItemPost = (url, obj) =>
{
    return axios.post(url,obj)
}

const updateItem = (url, vars) =>
{
    return axios.put(url +"?" + vars)
}

const deleteItem = (url, id) =>
{
    return axios.delete(url +"/" + id)
}



export default {getItems, getItem, getItemPost, updateItem, deleteItem, addItemPost}