import React from 'react';
import './Loader.css';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader(props) {

    const loader_classes = `loading-block img-holder ${props.showLoader ? 'visible' : ''}`;

  return (
    <div className={loader_classes}>
       <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress
                size={200}
                thickness={5}
                style={{ color: props.color }}
            />
        </Stack>
    </div>
    
  )
}
