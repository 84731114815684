import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './ProjectContent.css';
import Projects from '../../components/projects/Projects';
import Loader from '../../components/loader/Loader';
import CrmUtils from '../../services/crm-utils/CrmUtils';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import successImg from '../../assets/images/svg/thanks-img.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useTheme, createTheme } from '@mui/material/styles';
import { he } from 'date-fns/locale'
import { setMoreProjectsRD } from '../../services/store/GenericReducer';
import { Checkbox, ListItemText } from '@mui/material';

function ProjectContent(props) {

	const store = useSelector(state => state.reducer)
	let { id } = useParams()
	const [projectData, setProjectData] = useState(false)
	const [showLoader, setShowLoader] = useState(true)
	const [signupError, setSignupError] = useState(false)
	const [addresses, setAddresses] = useState([])
	const [showCities, setShowCities] = useState(false)
	const [comeTogether, setComeTogether] = useState('startMode')
	const [multipleSignup, setMultipleSignup] = useState(false)
	const [volunteersAmount, setVolunteersAmount] = useState('')
	const [volunteerForm, setVolunteerForm] = useState({})
	const [errorArray, setErrorArray] = useState([])
	const [afterFirstSubmit, setAfterFirstSubmit] = useState(false)
	const dispatch = useDispatch()
	const partnerColor = props.partner.partnerConfig.leadingColor;
	const [moreProjects, setMoreProjects] = useState([])
	const isAdmin = store.userDetails.isManager;
	const [thanksResponse, setThanksResponse] = useState(false)
	// const [thanksResponse, setThanksResponse] = useState(
	// 	{
	// 		"BubbleRegistrationResponseID": 2,
	// 		"Name": "רישום יחיד/קבוצה בפרויקט השמה ישירה",
	// 		"Title": "איזה כיף שבחרת להתנדב!",
	// 		"SubTitle": "ברגעים אלו נשלח אלייך מייל ו SMS של פרטי מקום ההתנדבות. יש ליצור עמם קשר לתיאום",
	// 		"ImageUrl": "https://www.ruachtova.org.il/wp-content/uploads/2022/02/landing-img-2.svg",
	// 		"ButtonTitle": "עקבו אחרנו בפייסבוק",
	// 		"ButtonLink": "https://www.facebook.com/RuachTova",
	// 		"IsActive": true,
	// 		"Description": null
	// 	}
	// )

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {PaperProps: {style: {maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250}}}
	//const [personName, setPersonName] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [namesList, setNamesList] = useState([]);
	const theme = createTheme({
		direction: 'rtl',
	});

	// function getStyles(name, personName, theme) {
	// 	return {
	// 		fontWeight:
	// 		personName.indexOf(name) === -1
	// 			? theme.typography.fontWeightRegular
	// 			: theme.typography.fontWeightMedium,
	// 	};
	// }

	const handleChange = (event) => {
		setSelectedOptions(event.target.value)
	}

	const getProject = async () => {
		try{
			let res = await CrmUtils.getProjectById(id)
			setProjectData(res.data)
			setShowLoader(false)
		}catch(error){
			console.log(error)
		}
	}

	const getVolunteerForm = async () => {
		try{
			let res = await CrmUtils.getVolunteerForm()
			setVolunteerForm(res.data)
			setNamesList(res.data.volunteersList)
		}catch(error){
			console.log(error)
		}
	}

	/**
	 * display the projects that will be interesting spesific to the client
	 */
	const moreProjectsForClient = async () => {  
		try{
			let projects = store.moreProjects

			//if(store.moreProjects.length === 0){
				// add try catch must !!!!!
				let res = await CrmUtils.getMoreProjects()
				projects = res.data.projects
			//}	

			let MoreProjectsFinal = removeCurrentProjectFromArray(projects)
			setMoreProjects(MoreProjectsFinal)
			dispatch(setMoreProjectsRD(MoreProjectsFinal))
		} catch(err){
			console.log(err)
		}
	}

	const removeCurrentProjectFromArray = (projects) => {
		return projects.filter(item => item.projectID != id)
	}

	const addressHandle = (adressArray) => {
		let finalArr = [];
		let inner = [];
		for(let i = 0; i < adressArray.length; i++){
			inner.push(adressArray[i].city.title)
			if( (i + 1) % 6 === 0 && i !== 0){
				finalArr.push(inner)
				inner = []
			}
			if(i === adressArray.length -1){
				if(inner.length !== 0){
					finalArr.push(inner)
				}
			}
		}
		setAddresses(finalArr)
	}

	const validateForm = () => {
		let innerErrorArray = []
		console.log(selectedOptions)
		console.log(volunteersAmount)
		if(comeTogether == 'startMode'){
			innerErrorArray.push('הגעה ביחד')
		}
		if(volunteersAmount == ''){
			innerErrorArray.push('כמות מתנדבים')
		}
		if(selectedOptions.length + 1 !== volunteersAmount){
			innerErrorArray.push('מספר עובדים נוספים')
		}
		
		console.log(innerErrorArray)
		setErrorArray(innerErrorArray)
		return innerErrorArray.length === 0
	}

	const signUpToVolunteer = async (signupJson) => {
		try{
			setShowLoader(true)
			let res = await CrmUtils.signUp(signupJson)
			setShowLoader(false)
			let error_message = res.data.errors && (res.data.errors[0].Value[0] || "לא ניתן להירשם שוב להתנדבות")
			res.data.success ? setThanksResponse(res.data.RegistraionInfo) : setSignupError(error_message)
		}catch(e){console.log(e); setShowLoader(false)}
	}

	const signup = (e) => {
		let selectedToSend = []
		selectedOptions && selectedOptions.length > 0 && selectedOptions.map(item => {
			let obj = {};
			obj.key = item.id;
			obj.value = item.lastName + " " + item.firstName;
			selectedToSend.push(obj)
		})
		e.preventDefault();
		setAfterFirstSubmit(true)
		if(multipleSignup){
			if(isAdmin){
				if(validateForm()){
					let signupJson = {
						"registrationType": 4,
						"projectIds": [+id],
						"volunteersList": selectedToSend,
						"volunteersNum": selectedToSend.length + 1
					}
					console.log('Admin Multiple-> ', signupJson)
					signUpToVolunteer(signupJson)
				}
			}else{
				let signupJson = {
					"registrationType": 2,
					"projectIds": [+id],
					"volunteersNum": volunteersAmount,
					"volunteersList": []
				}
				console.log('Regular employee Multiple-> ', signupJson)
				signUpToVolunteer(signupJson)
			}
		}else{
			let signupJson = {
				"registrationType": isAdmin ? 3 : 1,
				"projectIds": [+id],
				"volunteersList": []
			}
			console.log('Single-> ', signupJson)
			signUpToVolunteer(signupJson)
		}
	}

	useEffect(() => {
		setShowLoader(true)
		getProject(id)
		getVolunteerForm()
		moreProjectsForClient()
		setMultipleSignup('startMode')
		setComeTogether('startMode')
		setVolunteersAmount('')
		setSelectedOptions([])
		setErrorArray([])
		window.scrollTo(0, 0)
	},[id])

	useEffect(() => {
		if(afterFirstSubmit){
			//validateForm()
		}
	}, [volunteersAmount])

	useEffect(() => {
		projectData.addresses && addressHandle(projectData.addresses)
	}, [projectData])

	useEffect(() => {
		selectedOptions.length > 0 && console.log(selectedOptions)
	}, [selectedOptions])

  return (
    <>
		<Loader showLoader={showLoader} color={partnerColor}/>
		<div id="main" role="main">
			<section className="record-form__section" id="first-content-section">
				<div className="content-container">
					<div className="record-form__wrapper">
						<div className="project-info">
							<div className="project-info__title">
								<h1>פרטים והרשמה להתנדבות</h1>
								<h2>{projectData.title}</h2>
								<h3>{projectData.parentOrganizationTitle}</h3>
							</div>
							<div className="project-info__image"> <button className="share"> <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24">
										<path d="M15.482 16.636c-.2.195-.372.41-.521.637l-6.598-3.911c.175-.427.274-.89.274-1.377s-.1-.951-.273-1.378l6.599-3.881a3.836 3.836 0 0 0 3.219 1.732C20.287 8.458 22 6.785 22 4.73S20.287 1 18.182 1s-3.818 1.673-3.818 3.73c0 .468.093.914.254 1.327L8.01 9.944a3.84 3.84 0 0 0-3.192-1.689C2.713 8.255 1 9.928 1 11.985c0 2.056 1.713 3.729 3.818 3.729 1.335 0 2.51-.673 3.192-1.69l6.607 3.917a3.66 3.66 0 0 0 .865 3.968 3.853 3.853 0 0 0 2.7 1.091c.977 0 1.955-.364 2.7-1.09A3.661 3.661 0 0 0 22 19.273a3.66 3.66 0 0 0-1.119-2.637c-1.488-1.454-3.91-1.454-5.399 0zm2.7-14.89c1.684 0 3.054 1.338 3.054 2.983s-1.37 2.983-3.054 2.983c-1.684 0-3.055-1.338-3.055-2.983s1.37-2.983 3.055-2.983zM4.818 14.968c-1.684 0-3.054-1.339-3.054-2.983C1.764 10.34 3.134 9 4.818 9c1.685 0 3.055 1.339 3.055 2.984 0 1.644-1.37 2.983-3.055 2.983zm15.523 6.414a3.11 3.11 0 0 1-4.319 0 2.93 2.93 0 0 1-.895-2.11c0-.796.318-1.545.895-2.109a3.082 3.082 0 0 1 2.16-.872c.782 0 1.564.29 2.16.872a2.93 2.93 0 0 1 .894 2.11c0 .796-.317 1.546-.895 2.11z" fill="#298CCA" fillRule="nonzero" stroke="#298CCA" strokeWidth=".2"></path>
									</svg> שתפו </button>
								{(projectData && projectData.images) && 
												<div className="img-holder"> <img src={projectData.images[0].publicUrl} alt="project image"/>
													<h3 className="badge">{projectData.volunteerFields[0].title}</h3>
												</div>}
							</div>
						</div>
						<div className="project-info__description">
							<p className="subtitle">
								{
									projectData.volunteerFields?.map((item, i) => {
										return (<span key={item.volunteerFieldID}>
													{item.title}{(projectData.volunteerFields.length -1 === i) ? '.' : ', ' }
												</span>)
									})
								}
							</p>
							<p>{projectData.description}</p>
						</div>
						{ projectData &&
						<div className="contacts">
							<h4>חשוב לדעת לפני שנרשמים:</h4>
							<div className="contacts__info">
								<div className="card-info-item card-location with-cities-list"> <svg className="location-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="#298CCA">
										<path d="M7.997 10.481c-1.773 0-3.215-1.415-3.215-3.153 0-1.74 1.442-3.154 3.215-3.154 1.772 0 3.214 1.415 3.214 3.154 0 1.738-1.442 3.153-3.214 3.153m0-10.46C3.587.02 0 3.54 0 7.866c0 2.695 3.337 8.617 6.136 13.11A2.176 2.176 0 007.996 22c.766 0 1.462-.383 1.861-1.024 2.8-4.493 6.136-10.415 6.136-13.11 0-4.326-3.587-7.845-7.996-7.845" fillRule="evenodd"></path>
									</svg>
									<div className='cities_hover_block' data-default-text="בישובים" onMouseEnter={() => setShowCities(true)} onMouseLeave={() => setShowCities(false)}>{(!projectData.addresses || projectData.addresses?.length < 10) ? projectData.displayCityAndSubdistrict : `ב-${+projectData.addresses?.length} ישובים`}								
										{showCities && <div className="cities-expanded-list multicolumn-4">	 							
																
												{ 
													// When we have more then 9 addresses show addresses ul on hover
													addresses?.map((item, i) => { 
														return(
															<ul key={i}>
																{
																	item?.map((city, ind) => {
																		return(<li key={ind}>{city}</li>)
																	})
																}											
															</ul>
														)	
													})			
												} 																
											
											</div>}
										</div>
									</div>
								<div className="card-info-item working-mode"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
										<g fill="#298CCA" fillRule="nonzero">
											<path d="M.527 13h13.795a.51.51 0 0 0 .339-.13C14.793 12.752 17.816 9.98 18 4H3.184C3 9.429.217 11.98.188 12.006a.588.588 0 0 0-.156.624.53.53 0 0 0 .495.37zM17.466 1h-2.673V.5c0-.28-.235-.5-.534-.5-.3 0-.535.22-.535.5V1h-2.707V.5c0-.28-.236-.5-.535-.5-.3 0-.534.22-.534.5V1H7.276V.5c0-.28-.236-.5-.535-.5-.3 0-.534.22-.534.5V1H3.534C3.235 1 3 1.22 3 1.5V3h15V1.5c0-.28-.235-.5-.534-.5z"></path>
											<path d="M15.288 13.691c-.29.222-.655.343-1.03.343H3v1.475c0 .271.239.491.534.491h13.932c.295 0 .534-.22.534-.491V10c-1.03 2.324-2.468 3.503-2.712 3.691z"></path>
										</g>
									</svg>
									<p>{projectData.dateProjectText}</p>
								</div>
								<div className="card-info-item time"> <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18">
										<path d="m16.783 3.972-1.992-1.89a.77.77 0 0 0-1.049 0 .678.678 0 0 0 0 .995l.472.447-.967.918a8.24 8.24 0 0 0-4.425-1.738V1.406h.667c.41 0 .742-.315.742-.703C10.23.315 9.899 0 9.489 0H6.672c-.41 0-.741.315-.741.703 0 .388.332.703.741.703h.667v1.298C3.272 3.053 0 6.292 0 10.336 0 14.572 3.614 18 8.08 18c4.467 0 8.082-3.428 8.082-7.664 0-1.811-.659-3.526-1.867-4.9l.968-.917.471.447a.77.77 0 0 0 1.049 0 .678.678 0 0 0 0-.994zm-4.85 7.564c0 .388-.332.703-.741.703H8.08c-.41 0-.742-.315-.742-.703V6.199c0-.388.332-.703.742-.703s.741.315.741.703v4.634h2.37c.41 0 .741.315.741.703z" fill="#298CCA" fillRule="nonzero"></path>
									</svg>
									<p>{projectData.matchValue} שעות זיכוי</p>
								</div>
							</div>
						</div>
						}
						{!thanksResponse && volunteerForm && !signupError && <form className={'record-form__form '+ ((multipleSignup === 'startMode') ? "" : ((multipleSignup ? 'record-2__active ' + (errorArray.length > 0 ? 'error-form ' : '') : '') + (!multipleSignup ? 'record-1__active ' : '')))}>
							<div className="title">
								{volunteerForm.title && <h4>{volunteerForm.title}:</h4>}
								<p className="error-message" role="alert"> הדיווח לא נשלח משום שחלק מהפרטים חסרים:<br/> {errorArray.join(', ')}</p>
							</div>
							{volunteerForm?.volunteerFormFields && <p>{Array.isArray(volunteerForm.volunteerFormFields) && volunteerForm?.volunteerFormFields[0].title}</p>}  
							<div className="btns"> 
								<input type="radio" name="record" id="record-1" checked={multipleSignup === 'startMode' ? '' : !multipleSignup} onChange={(e) => setMultipleSignup(false)} value="עבורי" /> 
								{Array.isArray(volunteerForm.volunteerFormFields) && <label htmlFor="record-1"> {volunteerForm.volunteerFormFields[0]?.formFieldDetails.buttons[0]?.title} </label>}
								<input type="radio" name="record" id="record-2" checked={multipleSignup === 'startMode' ? '' : multipleSignup} onChange={(e) => setMultipleSignup(true)} value="רישום רבים"/> 
								{Array.isArray(volunteerForm.volunteerFormFields) && <label htmlFor="record-2"> {volunteerForm.volunteerFormFields[0]?.formFieldDetails.buttons[1]?.title} </label>} 
							</div>
							<div className="record-2">
								{
									isAdmin && 
									<><p>{volunteerForm.volunteerFormFields ? volunteerForm.volunteerFormFields[2].title : ""}</p>
									<div className="btns"> 
										<input type="radio" checked={comeTogether === 'startMode' ? '' : comeTogether} onChange={(e) => setComeTogether(true)} name="coming-together" id="coming-together-yes" value="כן"/> 
										<label htmlFor="coming-together-yes"> כן </label> 
										<input type="radio" checked={comeTogether === 'startMode' ? '' : !comeTogether} onChange={(e) => setComeTogether(false)} name="coming-together" id="coming-together-no" value="לא, בנפרד"/> 
										<label htmlFor="coming-together-no"> לא, בנפרד </label> 
									</div></>
								}
								<div className="participant-count"> 
								{Array.isArray(volunteerForm.volunteerFormFields) && <label htmlFor="participant-count">{volunteerForm.volunteerFormFields[isAdmin ? 3 : 2]?.title}</label>}
									<input type="number" value={volunteersAmount} onChange={(e) => setVolunteersAmount(+e.target.value)} name="participant-count" id="participant-count"/> 
								</div>
								{	(isAdmin && namesList.length > 0) &&
									<div className="who-add">
										<p>{volunteerForm.volunteerFormFields ? volunteerForm.volunteerFormFields[5].title : ""}</p>
											<Select
											sx={{
												width: '226px',
												margin: '0 auto',
												borderRadius: '0',
												fontSize: '18px',
												fontFamily: "AlmoniDL,Arial,sans-serif",
												"& legend": { display: 'none' },
												"& .MuiSelect-select": { padding: '0 0 3px 0' },
												"& .MuiListItemText-root .MuiTypography-root": { fontSize: '18px' }
											}}
											dir="rtl"
											labelId="multiple-name-label"
											id="multiple-name"
											className="dropdown who-add-dropdown"
											multiple
											value={selectedOptions}
											onChange={handleChange}
											input={<OutlinedInput label="Name" />}
											renderValue={(selected) => `נבחרו ${selected.length} עובדים`}
											MenuProps={MenuProps}
											>
											{namesList.map((item, index) => {											
												return <MenuItem
													sx={{"& li:not(:last-child)": {borderBottom: "1px solid #000"}}}
													key={index}	
													value={item}										
												>
													<Checkbox checked={selectedOptions.some(option => option.id === item.id)} />
              										<ListItemText primary={
														<div className='d-flex select_menu_item'>
															<div className='d-flex'><div className='spaceL'>{item.firstName}</div><div>{item.lastName}</div></div>
															<div>{item.workerId}</div>
														</div>
													} />							
												</MenuItem>											
											})}
											</Select>
									</div>
								}
							</div> <input type="submit" onClick={(e) => signup(e)} value="הרשמו לפרוייקט" className="btn btn-blue"/>
						</form>}
						{thanksResponse && !signupError && <div className="thanks">
							<div className="thanks__content">
								<h2 role="alert"> {thanksResponse.Title} <br/> <span>{thanksResponse.SubTitle}</span>  </h2>
								<div className="img-holder"> <img src={successImg} width="73" height="132px" alt="thanks image"/> </div> 
								<a href={thanksResponse.ButtonLink} target="_blank" className="btn border-btn">{thanksResponse.ButtonTitle}</a> 
								{/*<!-- img src=thanksResponse.ImageUrl -->*/}
							</div>
						</div>}
						{signupError && !thanksResponse && <div className="error-message reg_error">
							<p>{signupError}</p>
						</div>}
					</div>
				</div>
			</section>
			<section className="card-section cards-slider">
				<div className="content-container">
					<h2 className="title-with-line"><span>אולי יעניין אותך </span></h2>
						<div className="card-wrapper">
							{     		
								moreProjects?.length > 0 ? <Projects partner={props.partner} leadingColor={partnerColor} projects={moreProjects}/> : 
								<Box sx={{ width: '100%' }}>
									<LinearProgress />
								</Box>
							}
						</div>
					<div className="footer-section-link-wrapper"> <a href="index-column-mobile-card" className="footer-section-link"> הצג עוד </a> </div>
				</div>
			</section>
		</div>
	</>
  )
}

export default ProjectContent;