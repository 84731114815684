export default function FooterLogoRuachtova() {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="148" height="59" viewBox="0 0 148 59">
        <title>רוח טובה - דף הבית</title>
        <g fill="none" fillRule="evenodd">
          <path fill="#28B468" d="M111.963 53.53a7.085 7.085 0 10-13.639-3.841 7.085 7.085 0 0013.639 3.841" />
          <path fill="#9AC3E7" d="M105.635 42.83a6.673 6.673 0 00-4.616-8.234 6.673 6.673 0 00-8.235 4.615 6.675 6.675 0 0012.851 3.62" />
          <path fill="#28B468" d="M109.007 23.823a7.63 7.63 0 10-14.689-4.138 7.63 7.63 0 0014.689 4.138" />
          <path fill="#1D8BC8" d="M110.27 21.348a2.998 2.998 0 10-5.772-1.626 2.998 2.998 0 005.772 1.626m-7.148-9.798a1.5 1.5 0 10-2.888-.81 1.5 1.5 0 002.889.809" />
          <path fill="#EC5D2B" d="M141.898 21.622a7.22 7.22 0 10-13.9-3.915 7.22 7.22 0 0013.9 3.915" />
          <path fill="#EED4E5" d="M138.275 33.482a5.724 5.724 0 00-11.016-3.104 5.723 5.723 0 0011.017 3.104" />
          <path fill="#EE9FC5" d="M138.379 51.204a7.22 7.22 0 00-4.993-8.908 7.22 7.22 0 10-3.915 13.9 7.22 7.22 0 008.908-4.992m-.954-34.806c1.57-5.576-1.677-11.37-7.254-12.94-5.576-1.571-11.37 1.676-12.942 7.253-1.57 5.576 1.678 11.37 7.255 12.94 5.576 1.572 11.37-1.675 12.94-7.253" />
          <path fill="#1D8BC8" d="M109.232 13.978a3 3 0 00-2.072-3.698 2.996 2.996 0 10-1.625 5.77 2.994 2.994 0 003.697-2.072" />
          <path fill="#EE9FC5" d="M147.173 31.034a6.128 6.128 0 00-4.238-7.562 6.13 6.13 0 104.238 7.562" />
          <path fill="#D23E95" d="M125.342 18.091a7.222 7.222 0 00-13.901-3.915 7.222 7.222 0 004.993 8.908 7.222 7.222 0 008.908-4.993" />
          <path fill="#EC5D2B" d="M128.287 54.872a2.59 2.59 0 10-4.982-1.403 2.59 2.59 0 004.982 1.403" />
          <path fill="#9AC3E7" d="M117.47 55.081a4.632 4.632 0 10-5.714 3.203 4.632 4.632 0 005.715-3.203" />
          <path fill="#99CA4D" d="M110.729 43.84a5.723 5.723 0 10-11.018-3.101 5.723 5.723 0 0011.018 3.101" />
          <path fill="#1D8BC8" d="M104.778 33.814a8.04 8.04 0 00-15.475-4.359 8.04 8.04 0 0015.475 4.359" />
          <path fill="#F05640" d="M145.874 43.69a8.31 8.31 0 10-16-4.506 8.31 8.31 0 0016 4.506" />
          <path fill="#FDD402" d="M118.607 8.834a6.948 6.948 0 10-13.376-3.767 6.948 6.948 0 0013.376 3.767" />
          <path stroke="#000" strokeWidth=".4" d="M.28 30.498h78.185" />
          <path fill="#000" d="M4.349 37.616h1.264v-1.462H4.349v1.462zm-2.017 0h1.28v-1.462h-1.28v1.462zm-.67 6.622c.156.371.41.638.761.798.351.162.833.242 1.444.242h1.85v-5.021a27.287 27.287 0 00-1.91-.093c-.592 0-1.06.084-1.406.25a1.475 1.475 0 00-.746.8c-.15.367-.225.863-.225 1.485 0 .654.078 1.166.233 1.54zm7.141 1.04v1.282H3.701c-.922 0-1.65-.121-2.181-.362a2.244 2.244 0 01-1.158-1.192C.12 44.453 0 43.69 0 42.715c0-.956.116-1.713.347-2.27.23-.558.606-.961 1.128-1.207.521-.246 1.238-.369 2.152-.369.44 0 1.048.019 1.82.06.773.04 1.334.08 1.685.12v6.23h1.671zm2.271 3.966h1.37v-1.478h-1.37v1.478zm3.642-3.966v1.282H8.803v-1.282h2.242V39.02h1.429v6.26h2.242z" />
          <path fill="#000" d="M18.026 49.215h1.264v-1.45h-1.264v1.45zm-2.016 0h1.278v-1.45H16.01v1.45zm4.62-3.937v1.282h-5.914v-1.282h2.242V39.02h1.429v6.26h2.243z" />
          <path fill="#000" d="M25.496 41.033a7.22 7.22 0 00-1.497 2.036c-.381.768-.571 1.36-.571 1.772v.437h6.303V42.88c0-.592-.107-1.096-.323-1.508-.215-.413-.496-.719-.841-.92a2.18 2.18 0 00-1.106-.302c-.693 0-1.348.294-1.965.883zm7.02 4.245v1.282H20.628v-1.282h1.369v-9.456h1.43v6.228c.641-.995 1.293-1.775 1.955-2.337.663-.563 1.455-.844 2.378-.844.662 0 1.247.153 1.752.46.507.306.906.751 1.197 1.335.29.583.436 1.286.436 2.11v2.504h1.37zm3.28-9.456V46.56h-3.28v-1.282h1.851v-9.456zM38.76 46.56h1.429V35.822H38.76zm10.562 1.387c0 .674.19 1.195.572 1.561.38.368.957.55 1.73.55.301 0 .704-.026 1.211-.082a22.127 22.127 0 001.588-.234l.165 1.116c-.511.12-1.064.228-1.655.324-.592.095-1.123.143-1.595.143-1.124 0-1.98-.3-2.573-.905-.592-.602-.888-1.458-.888-2.563 0-1.016.26-1.782.782-2.3.522-.519 1.3-.777 2.333-.777.502 0 .973.053 1.414.158.441.106.793.23 1.053.37v-3.74c0-.966-.486-1.448-1.46-1.448-.45 0-1.493.08-3.129.24l-.045-1.008a20.27 20.27 0 011.836-.34 12.23 12.23 0 011.67-.144c.792 0 1.415.194 1.866.582.452.386.677 1.042.677 1.967v5.142H53.46a5.578 5.578 0 00-1.015-.354 4.767 4.767 0 00-1.197-.157c-1.284 0-1.925.633-1.925 1.899m9.192-3.37c.242.468.748.7 1.52.7h1.61V40.12a9.399 9.399 0 00-1.31-.105c-.812 0-1.377.213-1.691.64-.316.429-.48 1.14-.49 2.134 0 .724.12 1.32.361 1.788m3.093-5.746a15.35 15.35 0 011.467.219v6.454c0 1.518-.198 2.696-.595 3.537-.397.839-1.136 1.26-2.22 1.26-.42 0-1.213-.082-2.377-.242v-1.282c.983.16 1.74.241 2.272.241.341 0 .618-.06.828-.182.21-.12.371-.358.481-.716.11-.357.166-.877.166-1.56h-2.06c-1.166 0-1.93-.357-2.297-1.072-.365-.712-.549-1.598-.549-2.654.01-.994.12-1.788.324-2.382.205-.593.547-1.028 1.024-1.305.476-.276 1.126-.414 1.948-.414.46 0 .99.032 1.588.098m8.945 7.729h-2.077v.09c0 1.186-.206 2.095-.617 2.722-.411.628-1.034.944-1.866.944-.23 0-.481-.016-.751-.045l-.362-.032v-1.266c.331.03.602.045.812.045.452 0 .793-.18 1.024-.543.23-.362.346-.87.346-1.523v-7.918h1.414v6.243h2.077v1.283z" />
          <path fill="#000" d="M73.832 35.822V46.56h-3.28v-1.282h1.851v-9.456zm2.965 10.738h1.43V35.822h-1.43zM10.25 24.93H7.362v-9.343H.28L.68 13h8.438c.62 0 1.131.535 1.131 1.182v10.747zm-10.08 0l.11-6.244 2.753-.334v6.577H.17zm22.2-.024H12.2l.379-2.563h6.127v-6.757h-6.084l.4-2.586h7.416c.622 0 1.132.536 1.132 1.182v8.183l1.134.356-.334 2.185zm3.727 0v-9.32h-1.886L24.609 13h3.309c.577 0 1.043.467 1.043 1.003v10.903h-2.864zm14.786-2.563c-1.622 1.494-5.04 2.586-7.793 2.854l-2.021-9.967.377-2.23h2.11l1.732 9.008c1.066-.2 2.353-.58 3.219-1.226l1.088-5.196H36.02l.377-2.585h5.773l.334 2.117-1.621 7.225zM60.13 24.93h-2.8v-9.343h-4.15v9.342h-2.953v-8.34l1.443-1.002h-1.954l.4-2.587h8.881c.622 0 1.132.535 1.132 1.182v10.747zm4.06-.024v-9.32h-1.888l.4-2.586h3.308c.578 0 1.044.467 1.044 1.003v10.903H64.19zm14.275.024h-2.909v-9.343h-6.55l.4-2.587h7.904c.644 0 1.155.535 1.155 1.182v10.747z" />
        </g>
      </svg> 
    )
}