import { createSlice } from '@reduxjs/toolkit'
import { getLocalStoragKey, setLocalStorage } from '../GenericLocalStorage'

const localStorageAllProjects = getLocalStoragKey('gens', 'allProjects')
const localStorageDisplayProjects = getLocalStoragKey('gens', 'displayProjects')
const localStorageMoreProjects = getLocalStoragKey('gens', 'moreProjects')
const localStorageDateRange = getLocalStoragKey('gens', 'dateRange')
const localStorageUserDetails = getLocalStoragKey('gens', 'userDetails')
const localStorageFilterChoices = getLocalStoragKey('gens', 'filterChoices')
const localStorageSeasonalFilter = getLocalStoragKey('gens', 'seasonalFilter')

export const GenericReducer = createSlice({
  name: 'reducer',
  initialState: {
    allProjects: localStorageAllProjects ? localStorageAllProjects : [],
    moreProjects: localStorageMoreProjects ? localStorageMoreProjects : [],
    displayProjects: localStorageDisplayProjects ? localStorageDisplayProjects : [],
    dateRange: localStorageDateRange ? localStorageDateRange : false,
    userDetails: localStorageUserDetails ? localStorageUserDetails : {},
    partner: false,
    popupLoader: false,
    selectedLabel: false,
    projectsBanner: false,
    srcFilters: false,
    tempFilters: false,
    filterChoices: localStorageFilterChoices ? localStorageFilterChoices : false,
    headerMainLink: true,
    seasonalFilter: localStorageSeasonalFilter ? {...localStorageSeasonalFilter, display: false} : {display: false, value: false}
  },
  reducers: {
    clearAll: (state, action) => {
      const { payload } = action
      state.allProjects = []
      state.moreProjects = []
      state.displayProjects = []
      state.dateRange = false
      state.userDetails = {}
      state.partner = false
      state.popupLoader = false
      state.selectedLabel = false
      state.projectsBanner = false
      state.srcFilters = false
      state.tempFilters = false
      state.seasonalFilter = {display: false, value: false}
      state.filterChoices = false
      state.headerMainLink = true
      setLocalStorage('gens', state)
    },
    setAllProjects: (state, action) => {
      const { payload } = action
      state.allProjects = payload
      setLocalStorage('gens', state)
    },
    setSeasonalFilterRdx: (state, action) => {
      const { payload } = action
      state.seasonalFilter = payload
      setLocalStorage('gens', state)
    },
    setDisplayProjects: (state, action) => {
      const { payload } = action
      state.displayProjects = payload
      setLocalStorage('gens', state)
    },
    setMoreProjectsRD: (state, action) => {
      const { payload } = action
      state.moreProjects = payload
      setLocalStorage('gens', state)
    },
    setDateRange: (state, action) => {
      const { payload } = action
      state.dateRange = payload
      setLocalStorage('gens', state)
    },
    setUserDetails: (state, action) => {
      const { payload } = action
      state.userDetails = payload
      setLocalStorage('gens', state)
    },
    setPopupLoader: (state, action) => {
      const { payload } = action
      state.popupLoader = payload
      setLocalStorage('gens', state)
    },
    setSelectedLabel: (state, action) => {
      const { payload } = action
      state.selectedLabel = payload
      setLocalStorage('gens', state)
    },
    setProjectsBanner: (state, action) => {
      const { payload } = action
      state.projectsBanner = payload
      setLocalStorage('gens', state)
    },
    setRdxPartner: (state, action) => {
      const { payload } = action
      state.partner = payload
      setLocalStorage('gens', state)
    },
    setSrcFilters: (state, action) => {
      const { payload } = action
      state.srcFilters = payload
      setLocalStorage('gens', state)
    },
    setTempFilters: (state, action) => {
      const { payload } = action
      state.tempFilters = payload
      setLocalStorage('gens', state)
    },
    setFilterChoices: (state, action) => {
      const { payload } = action
      state.filterChoices = payload
      setLocalStorage('gens', state)
    },
    setHeaderMainLink: (state, action) => {
      const { payload } = action
      state.headerMainLink = payload
      setLocalStorage('gens', state)
    }
  }
});

export const {  setAllProjects, setDateRange, setMoreProjectsRD, setUserDetails, setPopupLoader, 
                setSelectedLabel, setProjectsBanner, setRdxPartner, setSrcFilters, setTempFilters,
                setDisplayProjects, setFilterChoices, setHeaderMainLink, clearAll, setSeasonalFilterRdx } = GenericReducer.actions

export default GenericReducer.reducer;
