import React from 'react';

import AccountParticipantTableEmpty from '../account-participant-table-empty/AccountParticipantTableEmpty';
import AccountParticipantTable from '../account-participant-table/AccountParticipantTable';
import './AccountParticipant.css';

export default function AccountParticipant(props) {

  const is_content = true;  
  const leadingColor = props.partner.partnerConfig.leadingColor;
  const display_content = is_content ? <AccountParticipantTable leading_color={leadingColor}/> : <AccountParticipantTableEmpty/>;
  
  return (    
    <>
        {display_content}
    </>
  )
}
