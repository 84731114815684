import React from 'react';
import './Input.css';
import { TextField } from '@mui/material';

export default function Input(props) {

  let classes = `field-wrapper ${props.error}`;

  const { name, label, value, onChange, error = null } = props;

  return (
    <div className={classes}>
      <TextField  
        label={label} 
        name={name} 
        value={value} 
        onChange={onChange} 
        {...(error && {error: true, helperText: error})}
      />
    </div>
  )
}
