import React from 'react'
import './AccordionBlock.css'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function AccordionBlock(props) {

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const status_classes = `reports-status ${props.data.status === 'נרשם' ? 'green-status' : 'event-status'}`;

  return (
    <Accordion expanded={expanded === props.panel_num} onChange={handleChange(props.panel_num)}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
        >
            <Typography> 
                <span className="event-info__name info-item"><strong className="info-label event-info__name"> <span>שם הפרוייקט</span> </strong>
                    <span className="event-name">{props.data.projectTitle}</span>
                </span>
                <span className="event-info__date info-item"><strong className="info-label event-info__date"> <span className="filtered-label">תאריך</span> </strong>
                    <span className="event-date">{props.data.dateCreated}</span>
                </span>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
                <span className="event-info__hours info-item" style={{display: 'flex'}}><strong className="info-label event-info__hours"> <span>מס׳ שעות מזכות</span> </strong>
                    <span className="event-hours">{props.data.matchValue}</span>
                </span>
                <span className="event-info__practice-hours info-item" style={{display: 'flex'}}><strong className="info-label event-info__practice-hours"> <span>שעות בפועל</span> </strong>
                    <span className="event-hours">{props.data.matchCount}</span>
                </span>
                <span className="event-info__status info-item" style={{display: 'flex'}}><strong className="info-label event-info__status"> <span className="filtered-label">סטטוס</span> </strong>
                    <span className={status_classes}>{props.data.isActive && 'פעיל'}</span>
                </span>
                <a href="#" className="cancel-event-btn" onClick={ () => props.cancelRegistration(props.data.id)} style={{display: 'flex'}}>ביטול הרשמה</a>
            </Typography>
        </AccordionDetails>
    </Accordion>
  )
}
