export default function AmountIcon() {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="37" viewBox="0 0 40 37">
          <g fill="red" fillRule="nonzero">
              <path d="M36.484 0H3.516C1.577 0 0 1.455 0 3.243v20.18c0 1.789 1.577 3.244 3.516 3.244h32.968c1.939 0 3.516-1.455 3.516-3.244V3.243C40 1.455 38.423 0 36.484 0zm1.172 23.423c0 .597-.525 1.082-1.172 1.082H3.516c-.647 0-1.172-.485-1.172-1.082V3.243c0-.596.525-1.08 1.172-1.08h32.968c.647 0 1.172.484 1.172 1.08v20.18z"/>
              <path d="M13.546 11.356c.6-.733.964-1.687.964-2.73 0-2.306-1.774-4.182-3.954-4.182S6.6 6.32 6.6 8.626c0 1.043.364 1.997.964 2.73-1.86 1.11-3.12 3.218-3.12 5.632v.76c0 .63.482 1.14 1.078 1.14h10.065c.596 0 1.079-.51 1.079-1.14v-.76c0-2.414-1.26-4.523-3.12-5.632zm-4.788-2.73c0-1.048.806-1.9 1.798-1.9.99 0 1.797.852 1.797 1.9s-.806 1.9-1.797 1.9c-.992 0-1.798-.852-1.798-1.9zm-2.14 7.982c.182-2.128 1.878-3.801 3.938-3.801 2.059 0 3.756 1.673 3.938 3.801H6.617zM37.266 30H8.203c-.164 0-.326.027-.476.078L.696 32.503c-.423.145-.696.471-.696.83 0 .36.273.685.696.831l7.031 2.424c.15.052.312.079.476.079h29.063c1.507 0 2.734-.952 2.734-2.122v-2.424C40 30.951 38.773 30 37.266 30zM9.375 31.818h22.813v3.03H9.375v-3.03zm-2.344.49v2.05l-2.974-1.025 2.974-1.025zm30.625 2.237c0 .168-.175.303-.39.303H34.53v-3.03h2.735c.215 0 .39.136.39.303v2.424zM15.588 20H5.523c-.596 0-1.079.497-1.079 1.111s.483 1.111 1.079 1.111h10.065c.596 0 1.079-.497 1.079-1.11 0-.615-.483-1.112-1.079-1.112zM34.327 4.444H21.228c-.678 0-1.228.498-1.228 1.112 0 .613.55 1.11 1.228 1.11h13.1c.678 0 1.228-.497 1.228-1.11 0-.614-.55-1.112-1.229-1.112zM34.327 7.778H21.228c-.678 0-1.228.51-1.228 1.14v12.164c0 .63.55 1.14 1.228 1.14h13.1c.678 0 1.228-.51 1.228-1.14V8.918c0-.63-.55-1.14-1.229-1.14zM33.1 19.942H22.456v-9.884H33.1v9.884z"/>
              <path d="M26.571 17.44a1.3 1.3 0 0 0 .881.338 1.3 1.3 0 0 0 .881-.337l3.524-3.255a1.09 1.09 0 0 0 0-1.627 1.32 1.32 0 0 0-1.762 0L27.452 15l-.88-.814a1.32 1.32 0 0 0-1.763 0 1.09 1.09 0 0 0 0 1.628l1.762 1.627z"/>
          </g>
      </svg> 
    )
}