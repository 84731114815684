import React, { useEffect, useState } from 'react'
import './Accessibility.css'
import CrmUtils from '../../services/crm-utils/CrmUtils'
import { useRef } from 'react'

export default function Accessibility() {

	const [accessibility, setAccessibility] = useState("")

	const getAccessibilityPage = async () => {
		try{ 
			let res = await CrmUtils.getStaticPage(3)
			Array.isArray(res.data.staticPages) && 
			setAccessibility(res.data.staticPages[0].rawHTML) 
		}catch(e){console.error(e)}   
	}

	let finalMount = useRef(true)
	useEffect(() => {
		if(finalMount.current){
			finalMount.current = false
			getAccessibilityPage()
		}
	}, [])

  return (
        <div id="main" role="main">
			<div dangerouslySetInnerHTML={{ __html: accessibility }} className="text-page" id="first-content-section">
			{/* <div className="text-page" id="first-content-section">
				<div className="content-container">
                    <h1>הצהרת נגישות</h1> <span className="text-page-date">ינואר , 2022</span>
					<h2></h2>
					<p>עמותת רוח טובה(ע"ר) ("<strong>העמותה</strong>") נוקטת את מירב המאמצים ומשקיעה משאבים רבים על מנת לספק לכל הפונים אליה שירות שוויוני, מכובד, נגיש ומקצועי. בהתאם לחוק שוויון זכויות לאנשים עם מוגבלויות תשנ"ח-1998 ולתקנות שהותקנו מכוחו, מושקעים מאמצים ומשאבים רבים בביצוע התאמות הנגישות הנדרשות שיביאו לכך שאדם עם מוגבלות יוכל לפנות לעמותה, באופן עצמאי ושוויוני.</p>
					<h2>שירות לקוחות נגיש</h2>
					<ul>
					<li><strong>הדרכות עובדים לשירות נגיש</strong> – מתקיימות הדרכות להכרת תחום הנגישות, יצירת מודעות בקרב העובדים והקניית כלים מעשיים למתן שירות נגיש.</li>
					<li><strong>מוקד טלפוני נגיש</strong>– המענה הקולי הונגש כך שהמידע מועבר בשפה ברורה וללא מוסיקת רקע.</li>
					<li><strong>העמותה אינה מקבלת קהל למעט במסגרת תוכנית למובילי לכידות חברתית ותוכנית יוצרים שינוי, כמפורט בהצהרת הנגישות של כל תוכנית.</strong></li>
					<li><strong>אמצעים נוספים ליצירת קשר</strong> <strong>עם העמותה:</strong> קיימים מספר אמצעים נוספים ליצירת קשר:
					<ul>
					<li>דואר אלקטרוני – info@ruachtova.org.il</li>
					<li>פניה באמצעות הדואר: שדרות שאול המלך 21, תל אביב-יפו</li>
					</ul>
					</li>
					</ul>
					<h2>נגישות אתר האינטרנט</h2>
					<p>אתר אינטרנט נגיש הוא אתר המאפשר לאנשים עם מוגבלות ולאנשים מבוגרים לגלוש באותה רמה של יעילות והנאה ככל הגולשים, כ- 20 עד 25 אחוזים מהאוכלוסייה נתקלים בקשיי שימוש באינטרנט ועשויים להיטיב מתכני אינטרנט נגישים יותר, כך על פי מחקר שנערך בשנת 2003 ע"י חברת מייקרוסופט.<br/>
					העמותה מאמינה ופועלת למען שוויון הזדמנויות במרחב האינטרנטי לבעלי לקויות מגוונות ואנשים הנעזרים בטכנולוגיה מסייעת לשימוש במחשב.</p>
					<p>&nbsp;</p>
					<h3><em>מידע על נגישות האתר</em></h3>
					<ul>
					<li>אתר זה עומד בדרישות תקנות שיוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות), התשע"ג 2013.</li>
					<li>התאמות הנגישות בוצעו עפ"י המלצות התקן הישראלי (ת"י 5568) לנגישות תכנים באינטרנט ברמת AA ומסמך0 הבינלאומי.</li>
					<li>הבדיקות נבחנו לתאימות הגבוהה ביותר עבור דפדפן כרום.</li>
					<li>האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.</li>
					<li>מותאם לתצוגה בדפדפנים הנפוצים ולשימוש בטלפון הסלואלרי.</li>
					<li>לשם קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש בתוכנת NVDA העדכנית ביותר.</li>
					<li>מסמכים או סרטוני וידאו שעלו לאתר לפני אוקטובר 2017 ייתכן שלא נגישים באופן מלא. במידה שנתקלתם במסמך כזה או בסרטון, תוכלו לפנות לרכזת נגישות של העמותה ואנחנו נדאג להנגיש לכם את המידע.</li>
					<li>מסירת מידע בפורמט נגיש&nbsp;–&nbsp;העמותה מעמידה עבור הפונים אליה אפשרות לקבלת מידע בפורמטים נגישים. מסירת המידע הינה ללא עלות ומיועדת עבור אנשים עם מוגבלות.&nbsp;לפניותומידע בנושא נגישות ניתן ליצור קשר עם רכזת הנגישות של העמותה&nbsp;שפרטיה מופיעים בהמשך ההצהרה.</li>
					</ul>
					<p>&nbsp;</p>
					<ul>
					<li><Link to="https://a-2-z.co.il/">בדיקות ויעוץ הנגישות בוצעו על ידי חברת A2Z נגישות ושיווק באינטרנט</Link></li>
					</ul>
					<p>&nbsp;</p>
					<h2>דרכי פנייה לבקשות והצעות לשיפור בנושא נגישות</h2>
					<p>יש לציין כי אנו ממשיכים במאמצים לשפר את נגישות העמותה כחלק ממחויבותנו לאפשר לכלל האוכלוסייה כולל אנשים עם מוגבלויות לקבל את השרות הנגיש ביותר.</p>
					<p>במידה ונתקלת בבעיה או בתקלה כלשהי בנושא הנגישות, נשמח שתעדכן אותנו בכך ואנו נעשה כל מאמץ למצוא עבורך פתרון מתאים ולטפל בתקלה בהקדם ככל שניתן.</p>
					<h3>פרטי רכזת הנגישות בעמותה</h3>
					<ul>
					<li>שם: &nbsp;שחר גולדברג</li>
					<li>טלפון : &nbsp;03-5436843</li>
					<li>דוא”ל :&nbsp; shahar@ruachtova.org.il</li>
					</ul>
					<p>&nbsp;</p>
					<h3>פרסום הצהרת הנגישות</h3>
					<p>הצהרת הנגישות עודכנה ביום: 18.1.21</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
				</div> */}
			</div>
		</div>
  )
}
