import React, { useState, useEffect } from 'react';
import AccountPersonalDetails from '../account-personal-details/AccountPersonalDetails';
import AccountProgressBlock from '../account-progress-block/AccountProgressBlock';
import AccountVolunteersFilter from '../account-volunteers-filter/AccountVolunteersFilter';
import './AccountInstractorTable.css';
import { DataGrid, heIL } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import AccordionBlock from '../accordion-block/AccordionBlock';
import {BrowserView, MobileView} from 'react-device-detect';
import { Box, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { he } from 'date-fns/locale'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import moment from "moment";

const allRows = [
    { 
        id:1, 
        comments: "", 
        date: '14.02.22', 
        from: '13:00', 
        to: '21:00', 
        status: "ממתין לאישור",
        activityTime: "9 שעות"
    },
    { 
        id:2, 
        comments: "", 
        date: '14.02.22', 
        from: '12:00', 
        to: '14:00', 
        status: "ממתין לאישור",
        activityTime: "2 שעות"
    },
    { 
        id:3, 
        comments: "הצטרף לכת של הארי קרישנה", 
        date: '14.02.22', 
        from: '13:00', 
        to: '15:00', 
        status: "ממתין לאישור", 
        activityTime: "2 שעות"
    },
    { 
        id:4, 
        comments: "", 
        date: '14.02.22', 
        from: '14:00', 
        to: '18:00', 
        status: "ממתין לאישור",
        activityTime: "4 שעות"
    },
    { 
        id:5, 
        comments: "קבענו להיפגש לארבע שעות אבל בסוף שי לא יכלה להישאר שעתיים. אז נפגשנו רק שעתיים.", 
        date: '14.02.22', 
        from: '12:00', 
        to: '16:00', 
        status: "מאושר",
        activityTime: "4 שעות"
    },
    { 
        id:6, 
        comments: "", 
        date: '14.02.22', 
        from: '11:00', 
        to: '14:00', 
        status: "מאושר",
        activityTime: "3 שעות"
    },
    { 
        id:7, 
        comments: "", 
        date: '14.02.22', 
        from: '13:00', 
        to: '17:00', 
        status: "מאושר",
        activityTime: "4 שעות"
    }
]

export default function AccountInstractorTable(props) {

    const [locale] = useState('heIL');
    const [rows, setRows] = useState([]);
    const [date, setDate] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [comments, setComments] = useState("");
    const [maxWidth] = useState('md');
    const fontSize = 14
    const htmlFontSize = 14
    const coef = fontSize / 14;
    const theme = useTheme();
    theme.typography = {
        font: "14px 'AlmoniDL' Ariel, sans-serif",
        pxToRem: size => `${(size / htmlFontSize) * coef}rem`,
    };
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function addRow(){
        let newDate = moment(date).utc(+3).format('DD.MM.YY')
        let newFrom = moment(from).utc(+3).format('HH:mm')
        let newTo = moment(to).utc(+3).format('HH:mm')
        let row = {
            id: rows.length + 1, 
            comments: comments, 
            date: newDate, 
            from: newFrom,    
            to: newTo, 
            status: "ממתין לאישור",
            activityTime: "8 שעות"
        }
        setRows((prevRows) => [...prevRows, row]);
        handleClose();
    }

    const columns = [
        { 
            field: 'date', 
            headerName: 'תאריך', 
            width: 129, 
            headerClassName: 'info-label' 
        },
        { 
            field: 'from', 
            headerName: 'משעה', 
            width: 129, 
            headerClassName: 'info-label' 
        },
        {
            field: 'to',
            headerName: 'עד שעה',
            width: 129, 
            headerClassName: 'info-label',
        },
        {
            field: 'activityTime',
            headerName: 'משך הפעילות',
            width: 129,
            headerClassName: 'info-label',
        },
        {
            field: 'status',
            headerName: 'סטטוס',
            width: 129,
            headerClassName: 'info-label',
            
        },
        { 
            field: 'comments', 
            headerName: 'הערות', 
            headerClassName: 'info-label',
            width: 400       
        }
    ];
    


    useEffect(() => {
        setRows(allRows);
    },[])

    function handleComments(e){
        let text = e.target.value;
        setComments(text);
    }

    const dialogBorderTop = `5px solid ${props.leadingColor}`;

    const accountFieldStyle = {
        "&.MuiInputBase-root": { 
            "& > fieldset": {
                textAlign: 'unset',
                right: 0
            }
         }
    }
  return (
    <>
        <div className="instructor-reports__top-block">
            <AccountVolunteersFilter/>
            <AccountProgressBlock 
                leading_color ={props.leadingColor}
                progress_text = "בוצעו 8/34 שעות חונכות" 
                data_progress_value = '8'
                data_max_value = '36'  
            />
        </div>

        <div className="reports-info">
            <BrowserView>
                <div className="reports-title">
                    <h2 className="title-with-line"> <span>יומן דיווחים</span> </h2>
                </div>
                <Box sx={{ width: '100%' }}>
                    <Stack direction="row" spacing={1}>
                        <button className="add-report-btn" size="small" onClick={() => handleClickOpen(3)}>
                            דיווח חדש
                        </button>
                    </Stack>
                    <ThemeProvider theme={themeWithLocale}>
                        <DataGrid 
                            autoHeight {...rows}
                            sx={{ 
                                backgroundColor: '#fff', 
                                marginBottom: '20px',
                                '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>div.MuiDataGrid-cell': {
                                    whiteSpace: 'break-spaces'
                                }
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={rows.length}
                            rowsPerPageOptions={[rows.length]}
                            localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                            disableSelectionOnClick
                            getCellClassName={(params) => {
                                if(params.field === 'status'){
                                    if(params.row.status === 'מאושר'){
                                        return "green-status";
                                    }else if(params.row.status === 'ממתין לאישור'){
                                        return "reports-status";
                                    }else{
                                        return '';
                                    }
                                }
                            }}
                        />
                    </ThemeProvider>
                </Box>
            </BrowserView>

            <MobileView>
                <div className="reports-title">
                    <h2 className="title-with-line"> <span>יומן דיווחים</span> </h2>
                    <button className="add-report-btn" size="small" onClick={() => handleClickOpen(3)}>
                            דיווח חדש
                    </button>
                </div>
                {     
                    rows?.map(function(item, i){
                        return <AccordionBlock panel_num={"panel" + i} key={i} data={item}/>
                    })
                }
            </MobileView>

            <Dialog 
                sx={{ 
                    '& .MuiPaper-root': {
                        borderTop: dialogBorderTop
                    }
                }}
                open={open} 
                onClose={handleClose}
                maxWidth={maxWidth}
            >
                <DialogTitle>רוני זילברשטיין - דיווח חדש</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <LocalizationProvider 
                            dateAdapter={AdapterDateFns}
                            adapterLocale={he}
                        >
                            <div>
                            <DatePicker
                                id='date_picker_report'
                                className="ml-5 date_picker"
                                label="תאריך"
                                value={date}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                sx = {accountFieldStyle}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <TextField role="span" {...params} />}
                            />

                            <TimePicker
                            role="span"
                                className="ml-5 time_picker"
                                label="משעה"
                                value={from}
                                sx = {accountFieldStyle}
                                onChange={(newValue) => {
                                    setFrom(newValue);
                                }}                              
                                renderInput={(params) => <TextField {...params} />}
                            />

                            <TimePicker
                            role="span"
                                className='time_picker'
                                label="עד שעה"
                                value={to}
                                sx = {accountFieldStyle}
                                onChange={(newValue) => {
                                    setTo(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            <br/>

                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={5}
                                placeholder="הערות"
                                style={{ marginTop: '20px' }}
                                value={comments}
                                onChange={(e) => {
                                    handleComments(e);
                                }}
                            />
                            </div>
                            
                        </LocalizationProvider>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <button className="btn btn-blue" onClick={addRow}>סיימתי</button>
                </DialogActions>
            </Dialog>
            
        </div>

        <AccountPersonalDetails 
            title="פרטים אישיים" 
            name="רוני זילברשטיין" 
            email="ronizilberstein@gmail.com"
            phone="052254326"
        />
   </>
  )
}


