import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DashboardBox from '../dashboard-box/DashboardBox';
import DashboardDialog from '../dashboard-dialog/DashboardDialog';
import './DashboardBoxSection.css'
import AmountIcon from '../../svg/amount';
import HoursSum from '../../svg/hoursSum';
import ProgectsIcon from '../../svg/progectsIcon';
import SinglesAndGroups from '../../svg/singlesAndGroups';
import Loader from '../loader/Loader';


export default function DashboardBoxSection(props) {
    const [open, setOpen] = useState(false)
    const [selectedLabel, setSelectedLabel] = useState(0)
    const [popupFilter, setPopupFilter] = useState(6)
    const [popupData, setPopupData] = useState({})
    const [labels, setLabels] = useState([])
    const handleClose = () => setOpen(false)
    const genericBlue = "#2a8cca";

    const boxesIcons = {
        0: <AmountIcon/>,
        1: <HoursSum/>,
        2: <ProgectsIcon/>,
        3: <SinglesAndGroups/>
    }

    const boxesGraphsEndPoints = {
        0: 'InfoVolunteers',
        1: 'InfoVolunteerHours',
        2: 'InfoProjects',
        3: 'InfoVolunteersGroups'
    }

    const init = () => {
        let tempLabels = []
        props.initSummaryData.tilesData && props.initSummaryData.tilesData.map((item, index) => {
            tempLabels.push({ 
                index: index, 
                icon: boxesIcons[index], 
                label: item.label, 
                labelNumber: item.infoData, 
                reqUrl: boxesGraphsEndPoints[index],
                chart: index === 3 ? 3 : 1,
                selectedDateRange: props.selectedDateRange,
                setSelectedLabel: setSelectedLabel, 
                mainColor: '#298CCA', 
                uniqueColor: '#A8D1EA', 
                clickOpen: setOpen
            })
        })
        setLabels(tempLabels)
    }

    useEffect(() => {
        props.initSummaryData.tilesData && init()
    }, [props.initSummaryData.tilesData])

    return (
            <Grid
                container
                spacing={{ xs: 2, sm: 4, md: 6 }}
                alignItems="center"
                justifyContent="space-between"
                id='dashboardBoxSection'
                className='dashboardBoxSection'
            >
                {(labels.length > 0 && props.partner) ? labels.map((item, index) => {
                    return (
                        <Grid key={`dashboard_labels_${index}`} item xs={12} sm={6} md={3} justifyContent="center" alignItems="center" >
                            <DashboardBox 
                                {...item} 
                                {...props} 
                                popupFilter={popupFilter} 
                                setPopupData={setPopupData} 
                                selectedDateRange={props.selectedDateRange} 
                                prevSelectedDateRange={props.prevSelectedDateRange}
                                open={open}
                            />
                        </Grid>
                    )
                }) : <Loader showLoader={true} color={genericBlue}/>}
                {labels.length > 0 && <DashboardDialog filtersInfo={props.filtersInfo} open={open} setPopupFilter={setPopupFilter} popupFilter={popupFilter} popupData={popupData} loaderColor={genericBlue} handleClose={handleClose} closeText='סגור' selectedLabel={labels ? labels[selectedLabel] : ""} {...props} />}
            </Grid>
    )
}