import React, { useEffect, useState } from 'react';
import FaqItem from '../faq-item/FaqItem';
import './FaqSubject.css';
import { isJsonStr } from '../../services/general-utils/generalUtils';

export default function FaqSubject(props) {
  
  const [htmlRow, setHtmlRow] = useState("")

  useEffect(() => {
    isJsonStr(props.staticPageItem.rawHTML) && extractRows()
  }, [])

  const extractRows = () => {
    setHtmlRow(JSON.parse(props.staticPageItem.rawHTML))
  }

  return (
    <div className="faq__list__item">
        <h2>{props.staticPageItem.title}</h2>
        {
          htmlRow && htmlRow?.map((item, index) => {
            let inx = index + 1
            let questionTitleKey = `Question${inx}Title`
            let questionTextKey = `Question${inx}Text`
            let answerTitleKey = `Answer${inx}Title`
            let answerTextKey = `Answer${inx}Text`
            return <FaqItem 
                      key={index}
                      question_title={item[questionTitleKey]}
                      question={item[questionTextKey]}
                      answer_title={item[answerTitleKey]} 
                      answer={item[answerTextKey]}
                    />
          })
        }
    </div>
  )
}
