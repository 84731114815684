import React from 'react';
import { Link } from 'react-router-dom';
import FooterLogoRuachtova from '../../svg/FooterLogoRuachtova';
import familyFoundationLogo from '../../assets/images/svg/footer-bottom-img.svg';

export default function Footer(props) {
  
  return (
    <footer id="footer" role="contentinfo">
		<div className="content-container">
			<div className="footer-nav">
			
				{/* footer items from partner json */}
				<ul>
					{   
						props.partner.partnerConfig.footerMenu?.map(function(item, i){
							return(
							<li key={item.menuItemFooterID}>
								<Link to={`${props.partner.partnerLink}/${item.link}`} className={ !i ? 'active' : ''}>{item.title}</Link>
							</li>
							)
						})
					}
				</ul>
				 
				<a href="https://www.ruachtova.org.il" target="_blank" className="footer-logo"> 
					<FooterLogoRuachtova/>
					<span className="sr-only">לעבור לעמוד הבית של האתר</span> 
				</a>
				<div className="family-foundation-logo"> 
					<img src={familyFoundationLogo} width="227" height="17" alt="family foundation logo" /> 
				</div>
			</div>
		</div>
	</footer>
  )
}
