import "./ErrorPage.css"

export default function ErrorPage() {
  return (
    <div id="main" role="main">
        <section className="page-not-found" id="first-content-section">
            <div className="content-outer">
                <div className="content-container">
                    <div className="page-not-found-text-wrapper">
                        <h1>404</h1>
                        <h2>הכל משתבש לטובה</h2>
                        <p>הדף שאתם מחפשים לא קיים</p> <a href="https://www.ruachtova.org.il" className="btn btn-blue">למציאת התנדבות</a>
                    </div>
                    <div className="page-not-found-img-wrapper">
                        <div className="img-holder">
                            <img src="https://www.ruachtova.org.il/wp-content/themes/ruachtova/images/svg/404-bg.svg" alt="page not found image"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="img-holder">
                <picture>
                    <source srcSet="https://www.ruachtova.org.il/wp-content/themes/ruachtova/images/svg/circles-mobile.svg"/>
                    <img className="page-not-found-bg" src="https://www.ruachtova.org.il/wp-content/themes/ruachtova/images/svg/circles.svg" alt="page not found image"/>
                </picture>
            </div>
        </section>
    </div>
  )
}
