export default function CommitmentIcon() {
    return  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
                <title>Group</title>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="-ראשי-2-פילטרים" transform="translate(-914.000000, -444.000000)" fill="#298CCA">
                        <g id="Group-4" transform="translate(693.149769, 435.000000)">
                            <g id="Group" transform="translate(221.681400, 9.681390)">
                                <path d="M2.40059928,3.92850903 C1.79952906,3.92850903 1.31049159,4.41749538 1.31049159,5.01861673 L1.31049159,8.72674187 L22.6895698,8.72674187 L22.6895698,5.01861673 C22.6895698,4.41749538 22.2005834,3.92850903 21.5994621,3.92850903 L18.7640265,3.92850903 L18.7640265,5.89125512 C18.7640265,6.25256198 18.470062,6.54652648 18.1087551,6.54652648 C17.7474482,6.54652648 17.4534837,6.25256198 17.4534837,5.89125512 L17.4534837,3.92850903 L6.54657762,3.92850903 L6.54657762,5.89125512 C6.54657762,6.25256198 6.25261312,6.54652648 5.89130626,6.54652648 C5.52994826,6.54652648 5.2360349,6.25256198 5.2360349,5.89125512 L5.2360349,3.92850903 L2.40059928,3.92850903 Z M1.31049159,21.5994109 C1.31049159,22.2005323 1.79952906,22.6895186 2.40059928,22.6895186 L21.5993598,22.6895186 C22.2004812,22.6895186 22.6894675,22.2005323 22.6894675,21.5994109 L22.6894675,10.0372335 L1.31049159,10.0372335 L1.31049159,21.5994109 Z M2.40059928,24.0000102 C1.07691534,24.0000102 0,22.923146 0,21.5994109 L0,5.01861673 C0,3.69493279 1.07691534,2.61801745 2.40059928,2.61801745 L5.2360349,2.61801745 L5.2360349,0.655271359 C5.2360349,0.293913365 5.52994826,0 5.89130626,0 C6.25261312,0 6.54652648,0.293913365 6.54652648,0.655271359 L6.54652648,2.61801745 L17.4534326,2.61801745 L17.4534326,0.655271359 C17.4534326,0.293913365 17.7473971,0 18.108704,0 C18.4700108,0 18.7639753,0.293913365 18.7639753,0.655271359 L18.7639753,2.61801745 L21.5994109,2.61801745 C22.9230949,2.61801745 24.0000102,3.69493279 24.0000102,5.01861673 L24.0000102,21.5994109 C24.0000102,22.923146 22.9230949,24.0000102 21.5994109,24.0000102 L2.40059928,24.0000102 Z" id="Fill-1" />
                                <path d="M10.2546567,20.5090322 C10.0796791,20.5090322 9.9151326,20.4409229 9.79133924,20.3172318 L6.30068339,16.8265248 C6.05682939,16.5739782 6.05682939,16.1741169 6.30334232,15.9188091 C6.42795381,15.7898513 6.59536376,15.7187762 6.77473886,15.7187762 C6.94542132,15.7187762 7.10705322,15.7841243 7.22987506,15.9027533 L10.2546567,18.9275861 L16.7728043,12.4094385 C16.8929161,12.2934174 17.054548,12.2281203 17.2253327,12.2281203 C17.3960663,12.2281203 17.5577494,12.2934174 17.6805201,12.4120463 C17.9403276,12.6631101 17.9475885,13.0787715 17.696627,13.3386302 L10.717872,20.3172318 C10.5941809,20.4409229 10.4296344,20.5090322 10.2546567,20.5090322" id="Fill-4" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg> 
}