import React from 'react';
import './AccountPersonalDetails.css';

export default function AccountPersonalDetails(props) {

  return (
    <div className="report-author-info">
        <h2 className="title-with-line"> <span>{props.title}</span> </h2>
        <div className="report-author-info__content">
            <p className="report-author-info__name"> <span className="report-author-info__label">שם </span>{props.name}</p>
            <p className="report-author-info__email"> <span className="report-author-info__label">מייל</span> <a href={`mailto:${props.email}`}>{props.email}</a> </p>
            <p className="report-author-info__tel"> <span className="report-author-info__label">טלפון</span> <a href={`tel:${props.phone}`}>{props.phone}</a> </p>
        </div>
    </div>
  )
}